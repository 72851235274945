/*刮刮卡*/

export default  class Scratchcard {
    constructor(config = {}) {
        this.config = Object.assign({
            // canvas元素
            canvas: null,
            // 直接全部刮开的百分比
            showAllPercent: 40,
            // 图片图层
            coverImg: null,
            // 纯色图层
            coverColor: null,
            // 全部刮开回调
            doneCallback: null,
            // 绘制完成后回调
            drawCallback: null,
            // 开始滑动回调
            slideCallback: null,
            // 擦除半径
            radius: 20,
            // 屏幕倍数
            pixelRatio: 1,
            // 展现全部的淡出效果时间（ms）
            fadeOut: 1000,
            // 文字提示
            coverText: ''
        }, config)
        this.canvas = this.config.canvas;
        this.canvasBox = this.config.canvasBox;
        this.ctx = null;
        this.offsetX = null;
        this.offsetY = null;
        this.isDown = false;
        this.done = false;
        this.hasStarted = false;
        this.init();
    }

    /**
     * 初始化， 绘制覆盖层
     */
    init() {
        const Rect=this.canvas.getBoundingClientRect()
        this.ctx = this.canvas.getContext('2d');
        this.canvas.width=Rect.width*this.config.pixelRatio;
        this.canvas.height=Rect.height*this.config.pixelRatio;
        this.offsetX = Rect.left;
        this.offsetY = Rect.top;
        console.log(Rect)
        this.addEvent();
        if (this.config.coverImg) {
            // 如果设置的图片涂层
            let coverImg = new Image();
            coverImg.src = this.config.coverImg;
            coverImg.crossOrigin = "Anonymous";
            coverImg.onload = () => {
                // this.ctx.imageSmoothingEnabled = false;
                this.ctx.drawImage(coverImg, -2, -2, this.canvas.width+4, this.canvas.height+4);
                this.fillText()
                this.ctx.globalCompositeOperation = 'destination-out';
            }
        } else {
            // 如果没设置图片涂层，则使用纯色涂层
            this.ctx.fillStyle = this.config.coverColor;
            this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
            this.fillText()
            this.ctx.globalCompositeOperation = 'destination-out';
        }

        this.config.drawCallback && this.config.drawCallback();
    }

    /**
     * 绘制覆盖层上文字提示
     */
    fillText() {
        const base=20;
        const size=base*this.config.pixelRatio;
        this.ctx.font = 'normal bold '+size+'px Arial,sans-serif '
        this.ctx.textAlign="center";
        this.ctx.textBaseline="middle";
        this.ctx.fillStyle="#090402";
        this.ctx.fillText(this.config.coverText,this.canvas.width/2,this.canvas.height/2);
    }
    // 添加事件
    addEvent() {
        this.canvas.addEventListener('touchstart', this.eventDown.bind(this), { passive: false });
        this.canvas.addEventListener('touchend', this.eventUp.bind(this), { passive: false });
        this.canvas.addEventListener('touchmove', this.scratch.bind(this), { passive: false });
        this.canvas.addEventListener('mousedown', this.eventDown.bind(this), { passive: false });
        this.canvas.addEventListener('mouseup', this.eventUp.bind(this), { passive: false });
        this.canvas.addEventListener('mousemove', this.scratch.bind(this), { passive: false });
    }
    eventDown(e) {
        e.preventDefault();
        this.isDown = true;
        if(!this.hasStarted && !this.done) {
            this.hasStarted = true
            this.config.slideCallback && this.config.slideCallback();
        }

    }
    eventUp(e) {
        e.preventDefault(e);
        this.isDown = false;
    }
    // 刮涂层
    scratch(e) {
        e.preventDefault();
        if (!this.done && this.isDown) {
            if (e.changedTouches) {
                e = e.changedTouches[e.changedTouches.length - 1];
            }
            let x = (e.clientX || e.pageX) - this.offsetX || 0,
                y = (e.clientY || e.pageY) - this.offsetY || 0;
            // y -=  (this.config.radius/2)
            this.ctx.beginPath()
            this.ctx.arc(x * this.config.pixelRatio, y * this.config.pixelRatio, this.config.radius * this.config.pixelRatio, 0, Math.PI * 2);
            this.ctx.fill();
            if (this.getFilledPercentage() > this.config.showAllPercent) {
                this.scratchAll()
            }
        }
    }
    // 刮开全部涂层
    scratchAll() {
        this.done = true;
        if (this.config.fadeOut > 0) {
            // 先使用CSS opacity清除，再使用canvas清除
            this.canvas.style.transition = 'all ' + this.config.fadeOut / 1000 + 's linear';
            this.canvas.style.opacity = '0';
            setTimeout(() => {
                this.clear();
            }, this.config.fadeOut)
        } else {
            // 直接使用canvas清除
            this.clear();
        }
        this.config.doneCallback && this.config.doneCallback();
    }
    // 清除全部涂层
    clear() {
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    }
    // 获取刮开区域百分比
    getFilledPercentage() {
        let imgData = this.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height);
        let pixels = imgData.data;
        const len=pixels.length;
        let transPixels = 0;
        for (let i = 0; i < len; i += 4) {
            if (pixels[i + 3] < 128) {
                transPixels++;
            }
        }
        return (transPixels / (len / 4) * 100).toFixed(2)
    }

}
