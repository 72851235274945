<template>
  <div>
    <van-popup v-model="show" :safe-area-inset-bottom="true" :overlay="showCover" @close="closePopup" position="bottom" :close-on-click-overlay="false" :lock-scroll="true" :closeable="true">
      <div class="content">
        <div class="title">{{ payData.boxTitle ? payData.boxTitle : '确认订单' }}</div>
        <div class="name row-between-center">
          <div class="left">商品名称</div>
          <div class="left fw6">{{ payData.subject ? payData.subject : '' }}</div>
        </div>
        <div v-if="payData.originalPriceStr" class="goodsNum row-between-center">
          <div class="left">原价</div>
          <div class="left fw6">¥{{ payData.originalPriceStr }}</div>
        </div>
        <div v-if="payData.totalAmount" class="goodsNum row-between-center">
          <div class="left">售价</div>
          <div class="left fw6">¥{{ payData.totalAmount }}</div>
        </div>
        <!-- <div class="c-container">
                    <div class="coupon-item">优惠立减</div>
                    <div class="coupon-item-tips">选择优惠券抵扣将以限时售价购买减免，不与礼品卡余额叠加抵扣，无法与其他优惠同享</div>
                    <div @click="selectCoupon()" class="coupon row-between-center">
                        <div class="left">权益卡优惠券</div>
                        <div v-if="list.length && selectedDiscount"><span class="s2">-¥</span><span class="s3">{{ selectedDiscount }}</span></div>
                        <div v-else-if="payData.firstPrice && !payData.extendsJson.giveUpFirstBuy">暂无可用优惠券</div>
                        <div v-else-if="list.length"><span class="s1">有{{list.length}}张可用</span></div>
                        <div v-else>暂无优惠券</div>
                    </div>
                </div> -->

        <div class="pay-type">选择支付方式</div>
        <!--支付宝支付-->
        <div v-if="appTypeStr !== 'mini'" @click="payType = 2" class="alipay row-between-center" :class="{ 'active': payType === 2 }">
          <div class="left"><img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/alipay.png" alt="">支付宝支付
            <span v-if="submitMoney >= 1000">（花呗支付3期免息）</span>
          </div>
        </div>
        <div @click="payType = 1" class="wechat row-between-center" :class="{ 'active': payType === 1 }">
          <div class="left"><img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/wechatpay.png" alt="">
            微信支付
          </div>
        </div>

        <div v-if="status === 'PER_SALE'" class="protocol" @click="protocol = !protocol" :class="{ shake: shake }">
          <p :class="{ active: protocol }"></p>
          <div><span>同意<span class="s1" @click.stop="checkProtocol(true)">《预售协议》</span>，未付尾款定金不退，付尾款后若退款定金可退。</span></div>
        </div>

        <div @click="pay()" class="pay-btn row-center-center fw6">￥{{ submitMoney }} 去支付</div>
      </div>
    </van-popup>

    <!-- 协议弹窗 -->
    <van-popup v-model="showProtocol" :overlay="true" position="bottom" style="background-color: transparent" @close="showProtocol = false" :closeable="true">
      <div class="protocol-box">
        <h4>“超鹿运动”2024春醒活动预售协议</h4>
        <p>{{ rules }}
        </p>
      </div>
    </van-popup>
    <!-- <coupon :discount="selectedDiscount" :unavailableList="unavailableList" :couponId="selectedCouponId" :list="list" @confirm="confirmVoucher" :showCoupon.sync="showCoupon"></coupon> -->
  </div>
</template>
<script>
import userMixin from "@/mixin/userMixin";
// import coupon from "@/components/pay-popup-card/coupon";
import { silentAppPay } from "@/lib/appMethod";
export default {
  mixins: [userMixin],
  props: {
    showCover: {
      type: Boolean,
      default: true
    },
    showPayPopup: {
      type: Boolean,
      default: false
    },
    payData: {
      type: Object,
      default() { return {} }
    },
    payUrl: {
      type: String,
      default: ''
    },
    status: {
      type: String
    }
  },
  // components: {coupon},
  data() {
    return {
      show: false,
      payType: 2, // 支付类型 默认1， 只在小程序内使用，默认只有微信
      showCoupon: false,
      // 鹿充券列表
      list: [],
      unavailableList: [],
      // 支付参数
      selectedDiscount: 0,
      selectedCouponId: '',
      // submitMoney: 0 // 最终支付金额

      showProtocol: false,
      protocol: false,
      shake: false,

      rules: `在同意本协议前请务必审慎阅读、充分理解各条款内容。
          1、本次活动设有预售，预售期间为2024年2月26日10:00:00至2024年3月3日23:59:59，买家支付定金；2024年3月4日10:00:00至2024年3月6日23:59:59 买家可进行商品尾款支付。
          2、预售定金规则：买家支付定金后应自觉遵守担保法的相关规定；支付定金后，因买家自身原因导致未如期支付尾款，或因买家自身原因申请退款的，定金均不退还。
          3、定金膨胀规则：部分预售商品支持定金做相应的膨胀，即买家支付定金后，将按照一定的膨胀比例享受高于所支付定金金额的抵扣权益，具体膨胀比例见购买页面。
          4、活动期间内，单笔实付金额大于1000元的商品，买家通过支付宝渠道支付的，支持分三期付款，同时商家将提供贴息服务，即买家分期付款产生的利息（利率为1.80%）由商家承担。
          5、预售商品不支持退款，在特殊情形下，成功支付全款（定金+尾款）的预售商品若发生退款，退款金额为买家实际支付金额减去买家已消耗商品原价，如买家付款时使用分期支付，商家还将在退款金额中扣除所贴利息。
          6、关于预售商品的具体使用规则，见各商品购买页面详情。`
    };
  },
  computed: {
    submitMoney() {
      if (!this.selectedDiscount || !this.selectedCouponId) {
        return this.payData.totalAmount
      }
      let p = (this.payData.totalAmount - this.selectedDiscount) > 0 ? (this.payData.totalAmount - this.selectedDiscount) : 0
      return p.toFixed(2)
    }
  },
  created() {
    this.payType = this.appTypeStr === 'mini' ? 1 : 2
  },
  methods: {
    closePopup() {
      this.showCoupon = false
      this.show = false
      this.$emit('update:showPayPopup', false)
    },
    selectCoupon() {
      if (this.payData.firstPrice && !this.payData.extendsJson.giveUpFirstBuy) {
        return false
      }
      this.showCoupon = true
    },

    confirmVoucher(item) {
      if (item) {
        this.selectedDiscount = item.discount || 0
        this.selectedCouponId = item.id || ''
      }
    },
    pay() {
      if (this.status === 'PER_SALE' && !this.protocol) {
        this.shake = true
        this.$toast({
          message: '请仔细阅读预售协议！',
          forbidClick: true,
          onClose: () => {
            this.shake = false
          }
        })
        return false
      }

      let payParam = JSON.parse(JSON.stringify(this.payData)), url = this.payUrl
      payParam.totalAmount = this.submitMoney
      payParam.discountId = this.selectedCouponId
      payParam.extendsJson.discountId = this.selectedCouponId
      this.show = false
      this.$emit('payComplete', (this.selectedCouponId || (!this.payData.extendsJson.giveUpFirstBuy && this.payData.firstPrice)) ? 1 : 0)
      silentAppPay(this.payType, payParam, url)
    },

    // 校验是否阅读完月卡协议
    checkProtocol(show) {
      this.showProtocol = true
    },
  },
  watch: {
    showPayPopup(val) { // 显示隐藏
      console.log(this.payData)
      this.show = val
      if (val) {
        this.selectedDiscount = this.payData.selectedDiscount || 0
        this.selectedCouponId = this.payData.discountId || 0 || ''
      } else {
        this.closePopup()
      }
    },

  },
};
</script>
<style lang="less" scoped>
.content {
  padding: 0 32px 32px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.coupon-box {
  background: #F5F5F5;
  padding-bottom: 0;
  height: 826px;
}

.pay-btn {
  width: 686px;
  height: 96px;
  background: #FFDE00;
  border-radius: 8px;
  color: #242831;
  font-size: 32px;
  margin-top: 72px;
  margin-bottom: 38px;
}

.coupon-item {
  color: #242831;
  font-size: 24px;
  font-weight: bold;
  padding: 32px 0 18px;
  border-top: 1px solid #E6E6E6;
}

.coupon-item-tips {
  color: #242831;
  font-size: 24px;
}

.coupon {
  height: 100px;
  box-sizing: border-box;
  //border-top: 1px solid #E6E6E6;
  border-bottom: 1px solid #E6E6E6;
  background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/arrow-right.png") no-repeat center right;
  background-size: 24px 24px;
  padding-right: 34px;
  margin-top: 12px;
  color: #9AA1A9;
  font-size: 24px;

  .coupon-price-red {
    color: #FB732E;
  }

  .s1 {
    font-weight: bold;
    color: #242831;
    font-size: 24px;
  }

  .s2 {
    font-family: BebasNeueBold;
    font-size: 32px;
    font-weight: bold;
    color: #F03C18;
    margin-right: 4px;
  }

  .s3 {
    font-family: BebasNeueBold;
    color: #F03C18;
    font-size: 40px;
    font-weight: bold;
  }
}

.coupon-title {
  font-weight: bold;
  color: #242831;
  font-size: 36px;
  padding-left: 56px;
  height: 144px;
  display: flex;
  align-items: center;
  background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/arrow-left.png") no-repeat center left;
  background-size: 40px 40px;
}

.coupon-list {
  height: calc(100% - 144px);
  overflow-y: scroll;
}

.coupon-list::-webkit-scrollbar {
  display: none;
}

.price {
  padding: 28px 0 32px;
}

.price>div .s1 {
  text-decoration: line-through;
  color: #6C727A;
  line-height: 28px;
  font-size: 24px;
  margin-right: 16px;
}

.price>div .s2 {
  font-family: BebasNeueBold;
  color: #242831;
  font-size: 24px;
  font-weight: bold;
}

.price>div .s3 {
  font-family: BebasNeueBold;
  color: #242831;
  font-size: 40px;
  font-weight: bold;
}

.left {
  font-size: 24px;
  color: #242831;
  line-height: 28px;
}

.title {
  color: #242831;
  font-size: 36px;
  font-weight: bold;
  padding: 48px 0;
}

.pay-type {
  color: #242831;
  font-size: 24px;
  font-weight: bold;
  padding: 32px 0 8px;
  margin-top: 48px;
}

.wechat,
.alipay {
  background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/normal.png") no-repeat center right;
  background-size: 40px 40px;
  padding: 24px 0;

  &.active {
    background-image: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/active.png");
  }

  div {
    display: flex;
    align-items: center;
  }

  img {
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }
}

.coupon-box .item {
  background-color: white;
}

.coupon-box .item {
  margin-bottom: 32px;
}

.coupon-box .item-i {
  display: flex;
  justify-content: space-between;
  //align-items: center;
}

.coupon-box .item-l {
  width: 192px;
  height: 200px;
  background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/card-coupon-active.png");
  background-size: 100% 100%;
  color: white;
  font-size: 20px;
  flex-shrink: 0;
}

.coupon-box .item-l>div:first-child {
  font-family: BebasNeueBold;
  font-size: 80px;
}

.coupon-box .item-l>div:first-child span {
  font-size: 36px;
}

.coupon-box .item-r {
  flex-grow: 1;
  box-sizing: border-box;
  padding-left: 16px;
  position: relative;
}

.coupon-box .item-r .coupon-tabs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;


}

.coupon-box .item-r .coupon-tabs span {
  padding: 4px 8px;
  font-weight: bold;
  color: #FB732E;
  font-size: 18px;
  border: 1px solid #FB732E;
  border-radius: 4px;
  margin-bottom: 8px;
  margin-right: 8px;
}

.coupon-box .item-r .coupon-date {
  margin-top: 38px;
  font-size: 20px;
  line-height: 23px;
  color: #6C727A;
  position: absolute;
  left: 16px;
  bottom: 24px;

}

.coupon-box .item-r .coupon-name {
  line-height: 28px;
  font-weight: bold;
  font-size: 24px;
  padding-top: 24px;
}

.goodsNum {
  margin-top: 48px;
}

.coupon-box .item-r .arrow {
  position: absolute;
  right: 4px;
  bottom: 8px;
  width: 24px;
  height: 24px;
  transition: transform .5s;
  padding: 20px;
}

.coupon-box .arrow.rotate {
  transform: rotateZ(180deg);
}

.c-container {
  margin-top: 48px;
}

.protocol {
  margin: 36px 0 0;
  display: flex;
  align-items: flex-start;

  &.shake {
    animation: shake .6s;
  }

  p {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    background: url("https://img.chaolu.com.cn/ACT/invite-2022/icon7.png") no-repeat center center;
    background-size: 100% 100%;

    &.active {
      background-image: url("https://img.chaolu.com.cn/ACT/invite-2022/icon6.png");
    }
  }

  div {
    font-size: 20px;
    line-height: 26px;
    color: #707072;
    padding-top: 7px;

    .s1 {
      font-weight: bold;
      color: #232831;
    }
  }
}

.protocol-box {
  height: 1200px;
  background: #FFFFFF;
  border-radius: 12px;
  margin: 0 auto;

  h4 {
    font-size: 36px;
    font-weight: 600;
    color: #242831;
    line-height: 36px;
    text-align: center;
    padding-top: 36px;
  }

  p {
    &::-webkit-scrollbar {
      display: none;
    }

    text-align: left;
    margin: 32px auto 56px;
    width: 702px;
    font-size: 28px;
    font-weight: 400;
    color: #3C454E;
    line-height: 44px;
    white-space: pre-line;
  }

}
</style>

