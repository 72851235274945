<template>
  <div class="mid-autumn">
    <nav-bar header-title="中秋博饼来超鹿" backColor="#fff" :show-back="true" :header-back="newAppBack" ref="head" />
    <div class="headbg">
      <img src="https://img.chaolu.com.cn/ACT/mid-autumn-2023/poster.png" class="head-img" alt="" />
      <div class="top-button">
        <div class="button f24" @click="shareShow = true">分享</div>
        <div class="button f24" @click="goRule">规则</div>
      </div>
    </div>

    <div class="content">
      <div class="title f44 fw6">去约课，获得更多博饼券</div>
      <div class="block a col-start-center">
        <div class="p2 f36 puhui">
          已打卡<span class="BebasNeueBold f60"> {{ activityInfo.scheduleNumber > 15 ? 15 : activityInfo.scheduleNumber }}/15 </span>节
        </div>
        <p class="p5 f22">*打卡3节团课得1张博饼券，最多可得5张<br />（仅统计非月卡免费课）</p>
        <div class="progress-box col-center-center">
          <div class="road">
            <div class="progress" :style="`width: ${(activityInfo.scheduleNumber * 100) / 15}%`"></div>
          </div>
          <div class="dots row-between-center">
            <div :class="['dot', activityInfo.scheduleNumber > 0 ? 'active' : '']"></div>
            <div :class="['dot', activityInfo.scheduleNumber >= 3 ? 'active ok' : '']"></div>
            <div :class="['dot', activityInfo.scheduleNumber >= 6 ? 'active ok' : '']"></div>
            <div :class="['dot', activityInfo.scheduleNumber >= 9 ? 'active ok' : '']"></div>
            <div :class="['dot', activityInfo.scheduleNumber >= 12 ? 'active ok' : '']"></div>
            <div :class="['dot', activityInfo.scheduleNumber >= 15 ? 'active ok' : '']"></div>
          </div>
          <div class="tips f22 fw6 row-between-center">
            <span>进度</span>
            <span>3节</span>
            <span>6节</span>
            <span>9节</span>
            <span>12节</span>
            <span>15节</span>
          </div>
        </div>
        <div @click="goAppoint(3)" class="p3 f40 fw6 row-center-center">去约课</div>
        <p class="p4 f24">打卡时间：9月5日-9月30日</p>
        <div @click="showRecord('scheduleRecords')" class="r-button f22 row-center-center">打卡记录<img src="https://img.chaolu.com.cn/ACT/mid-autumn-2023/icon.png" alt="" /></div>
      </div>
      <div class="block b col-start-center">
        <div class="p2 f36 puhui">
          已打卡<span class="BebasNeueBold f60"> {{ activityInfo.perNumber > 10 ? 10 : activityInfo.perNumber }}/10 </span>节
        </div>
        <p class="p5 f22">*打卡2节私教课得1张博饼券，最多可得5张<br />（打卡课程需签到成功）</p>
        <div class="progress-box col-center-center">
          <div class="road">
            <div class="progress" :style="`width: ${(activityInfo.perNumber * 100) / 10}%`"></div>
          </div>
          <div class="dots row-between-center">
            <div :class="['dot', activityInfo.perNumber > 0 ? 'active' : '']"></div>
            <div :class="['dot', activityInfo.perNumber >= 2 ? 'active ok' : '']"></div>
            <div :class="['dot', activityInfo.perNumber >= 4 ? 'active ok' : '']"></div>
            <div :class="['dot', activityInfo.perNumber >= 6 ? 'active ok' : '']"></div>
            <div :class="['dot', activityInfo.perNumber >= 8 ? 'active ok' : '']"></div>
            <div :class="['dot', activityInfo.perNumber >= 10 ? 'active ok' : '']"></div>
          </div>
          <div class="tips f22 fw6 row-between-center">
            <span>进度</span>
            <span>2节</span>
            <span>4节</span>
            <span>6节</span>
            <span>8节</span>
            <span>10节</span>
          </div>
        </div>
        <div @click="goAppoint(1)" class="p3 f40 fw6 row-center-center">去约课</div>
        <p class="p4 f24">打卡时间：9月5日-9月30日</p>
        <div @click="showRecord('perRecords')" class="r-button f22 row-center-center">打卡记录<img src="https://img.chaolu.com.cn/ACT/mid-autumn-2023/icon.png" alt="" /></div>
      </div>
      <div class="block col-start-center">
        <p class="p1 f36 fw6">已获得博饼券</p>
        <!-- 打卡数除以3 取商 -->
        <div class="p6 f28">
          博饼券 * <span class="BebasNeueBold">{{ activityInfo.drawsNum }}</span>
        </div>
        <div @click="goGambling" class="p3 orange f40 fw6 row-center-center">{{ activityStatus }}</div>
        <p class="p4 f24">博饼时间：截止至9月30日</p>
      </div>

      <img src="https://img.chaolu.com.cn/ACT/mid-autumn-2024/rule.png" alt="" class="rule" />
    </div>

    <van-popup v-model="recordShow" safe-area-inset-bottom position="bottom" closeable class="pop-radius" close-icon="https://img.chaolu.com.cn/ACT/teamWarband-202308/close2.png">
      <p class="record-title f32 fw6">{{ curRecords.name }}打卡记录</p>
      <div class="record-box" v-if="curRecords.list.length">
        <div class="record-item row-between-center f22" v-for="(item, index) in curRecords.list" :key="index">
          <div>
            <p class="f26 fw6">{{ item.projectName }}</p>
            <p class="p2">{{ item.name }} | {{ item.venueName }}</p>
          </div>
          <div style="text-align: right;">
            <p>{{ item.dayTime }}</p>
            <p class="p2">{{ item.startTime }}</p>
          </div>
        </div>
      </div>
      <div v-else class="empty">暂无打卡记录</div>
    </van-popup>

    <common-share :value="shareShow" :shareParams="shareParams" @close="shareShow = false" @share-success="shareShow = false" :shareItem="['posterfriend', 'postermessage', 'save']" :miniSharePoster="appTypeStr === 'mini'" :reload="true">
      <template slot="createDom">
        <div class="canvascss">
          <img src="https://img.chaolu.com.cn/ACT/mid-autumn-2023/share_poster2.png" class="canvascss_bg" />
          <div class="canvascss_left">
            <img :src="userDetail.headImg" />
          </div>
          <div class="canvascss_name ellipsis">
            {{ userDetail.nickName }}
          </div>
          <div class="canvascss_right">
            <img v-if="userDetail.qrCode" :src="'data:image/png;base64,' + userDetail.qrCode" />
          </div>
        </div>
      </template>
    </common-share>
  </div>
</template>
<script>
import { newAppBack, initBack, gotoAppPage, appOpenWeb } from '@/lib/appMethod'
import navBar from '@/components/nav-bar/nav-bar'
import commonShare from '@/components/commonShare'
import userMixin from '@/mixin/userMixin'
import headerMixin from '@/mixin/headerMixin'

const activityNo = '2024bobing'
const webUrl = `${window.location.origin}/#/superdeer-activity/mid-autumn-2024/intro`

export default {
  components: {
    navBar,
    commonShare,
  },
  mixins: [userMixin, headerMixin],
  data() {
    return {
      params: {},
      activityInfo: {},
      userDetail: {},
      recordShow: false,
      curRecords: { list: [] },

      shareParams: {
        title: '',
        miniImage: '',
        path: `/pages/webView/index?webUrl=${webUrl}`,
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },
      shareShow: false,
    }
  },
  computed: {
    activityStatus() {
      switch (this.activityInfo.activityActiveTimeCheck) {
        case 'ACTIVE':
          return '去博饼'
        case 'END':
          return '活动已结束'
        default:
          return '博饼活动暂未开始'
      }
    },
  },
  async created() {
    initBack()

    await this.$getUserId()
    this.params = {
      activityNo,
      userId: this.userId,
      token: this.token,
    }
    this.initQr()
    this.getActivity()

    let img = new Image()
    img.src = 'https://img.chaolu.com.cn/ACT/mid-autumn-2023/share_poster2.png'
    img.onload = () => {}
  },
  methods: {
    newAppBack,
    getActivity() {
      this.$axios.post(this.baseURLApp + '/punch/activity/midAutumnFestival', this.params).then((res) => {
        this.activityInfo = res.data
      })
    },
    showRecord(key) {
      this.curRecords.name = key === 'perRecords' ? '私教' : '团课'
      this.curRecords.list = this.activityInfo[key]
      this.recordShow = true
    },
    initQr() {
      this.$axios
        .post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
          token: this.token,
          userId: this.userId,
          width: 240,
          height: 240,
          webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
          scene: JSON.stringify({
            source: 'mid-autumn-2024',
            webUrl: webUrl,
          }),
        })
        .then((res) => {
          this.userDetail = res.data
        })
    },
    goAppoint(v) {
      gotoAppPage(v)
    },
    goRule() {
      window.scrollTo({ top: 2500, left: 0, behavior: 'smooth' })
    },
    goGambling() {
      appOpenWeb('', `${window.location.origin}/#/superdeer-activity/mid-autumn-2024/index`)
    },
  },
}
</script>
<style lang="less" scoped>
.mid-autumn {
  min-height: 100vh;

  * {
    box-sizing: border-box;
  }

  img {
    width: 100%;
    display: block;
  }

  .top-button {
    position: absolute;
    right: 0;
    top: 238px;
    width: 88px;
    z-index: 99;

    .button {
      line-height: 56px;
      background: rgba(36, 40, 49, 0.3);
      border-radius: 40px 0 0 40px;
      color: #fff;
      width: 100%;
      text-align: center;
      margin-bottom: 32px;
      padding-left: 6px;
    }
  }
  .content {
    padding: 41px 41px 74px;
    width: 750px;
    background: #fff4ed;
    border-radius: 60px 60px 0px 0px;
    margin: -60px 0 0;
    position: relative;

    .title {
      width: 100%;
      text-align: center;
      color: #e22134;
      padding: 0 0 26px;
      background-image: url(https://img.chaolu.com.cn/ACT/mid-autumn-2023/cloud.png);
      background-size: 100% 49px;
      background-repeat: no-repeat;
      background-position: 0 bottom;
      margin: 0 0 30px;
    }

    .block {
      width: 100%;
      height: 434px;
      background-size: 100% 100%;
      background-image: url(https://img.chaolu.com.cn/ACT/mid-autumn-2023/bg.png);
      margin: 0 0 46px;
      padding: 36px 0 30px;
      position: relative;
      text-align: center;
      &.a,
      &.b {
        height: 575px;
        padding: 128px 0 30px;
      }
      &.a {
        background-image: url(https://img.chaolu.com.cn/ACT/mid-autumn-2024/bg_a.png);
      }
      &.b {
        background-image: url(https://img.chaolu.com.cn/ACT/mid-autumn-2024/bg_b.png);
      }

      .p2 {
        color: #492525;
        margin: 0 0 12px;
        span {
          color: #e22134;
        }
      }

      .p3 {
        width: 558px;
        height: 100px;
        color: #fff;
        background-image: url(https://img.chaolu.com.cn/ACT/mid-autumn-2023/button_red.png);
        background-size: 100% 100%;
        margin: 36px 0 0;

        &.orange {
          background-image: url(https://img.chaolu.com.cn/ACT/mid-autumn-2023/button_orange.png);
        }
      }

      .p4 {
        color: #e22134;
        margin: 20px 0 0;
      }
      .p5 {
        color: #333333;
      }
      .p6 {
        color: #e22134;
        margin: 48px 0 0;
        span {
          font-size: 70px;
        }
      }
      .r-button {
        width: 130px;
        height: 46px;
        background: linear-gradient(180deg, #fc4b47 0%, #f80957 100%);
        border-radius: 0px 14px 0px 14px;
        position: absolute;
        right: 0;
        top: 38px;
        color: #fff2e1;

        img {
          width: 10px;
          margin: 0 0 0 8px;
        }
      }
      .progress-box {
        width: 532px;
        margin: 40px 0 0;
        position: relative;
        .road {
          width: 516px;
          height: 14px;
          background: #f6d7bf;
          border-radius: 7px;
          .progress {
            max-width: 100%;
            height: 14px;
            background: #e22134;
            border-radius: 7px;
          }
        }
        .dots {
          width: 100%;
          position: absolute;
          left: 0;
          top: -9px;
          .dot {
            width: 32px;
            height: 32px;
            background: #ffffff;
            border-radius: 50%;
            border: 6px solid #f6d7bf;
            &.active {
              border: 6px solid #e22134;
            }
            &.ok {
              background-image: url(https://img.chaolu.com.cn/ACT/mid-autumn-2024/check.png);
              background-size: 16px 14px;
              background-position: center;
              background-repeat: no-repeat;
            }
          }
        }
        .tips {
          width: 540px;
          margin: 20px 0 0;
          color: #492525;
        }
      }
    }
  }

  .pop-radius {
    padding: 44px 32px;
    border-radius: 24px 24px 0px 0px;
    background: linear-gradient(180deg, #fff 40px, #fff4ed 180px, #fff4ed 100%);

    .record-title {
      text-align: center;
      width: fit-content;
      border-radius: 9px;
      margin: 0 auto;
      padding: 0 0 4px;
      position: relative;

      &::after {
        content: '';
        width: 100%;
        height: 18px;
        border-radius: 9px;
        background: linear-gradient(270deg, rgba(255, 232, 206, 0) 0%, #fa6b76 100%);
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
      }
    }

    .record-box {
      margin: 58px 0 0;
      min-height: 40vh;
      max-height: 60vh;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }

    .record-item {
      width: 100%;
      height: 147px;
      padding: 0 33px;
      border: 1px solid #242831;
      border-radius: 14px;
      margin: 0 0 23px;

      .p2 {
        margin: 16px 0 0;
      }
    }

    /deep/.van-popup__close-icon--top-right {
      top: 44px;
    }

    .empty {
      color: #e22134;
      margin: 12vh 0;
      text-align: center;
    }
  }

  @bei: 1px;

  .canvascss {
    position: fixed;
    bottom: -200%;
    // top: 0;
    width: @bei*600;
    height: @bei*1066;
    margin: 0 auto;
    box-sizing: border-box;

    .canvascss_bg {
      width: 100%;
      height: 100%;
    }

    .canvascss_left {
      position: absolute;
      left: @bei*26;
      bottom: @bei*30;
      z-index: 1;
      width: @bei*100;
      height: @bei*100;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .canvascss_name {
      position: absolute;
      left: @bei*150;
      bottom: @bei*114;
      z-index: 1;
      width: @bei*252;
    }

    .canvascss_right {
      position: absolute;
      right: @bei*34;
      bottom: @bei*28;
      width: @bei*124;
      height: @bei*124;
      overflow: hidden;
      //background: url(https://img.chaolu.com.cn/ACT/anniversary-2023/border.png);
      background-size: 100% 100%;
      padding: @bei*12;

      img {
        border-radius: @bei*8;
        width: 100%;
        height: 100%;
      }
    }
  }

  /deep/.canvas-box {
    padding: 20px 40px !important;
    box-sizing: border-box;
    .create-img {
      width: 100% !important;
    }
  }
}
</style>
