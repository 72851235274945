<template>
  <div class="sb-bag">
    <div class="goods-img col-center-center" >
      <img :src="cardObj.goodsImg" :class="cardObj.deliveryPriceTag ? 'up' : ''" />
      <div v-if="cardObj.deliveryPriceTag" class="cc fw6">
        <div class="b1 row-center-center">
          <div class="col-start-center fw6">
            <span class="f14">原价</span>
            <span><span class="f12">￥</span><span class="f18">{{ cardObj.originPrice }}</span></span>
          </div>
          <p class="f18 le">-</p>
          <div v-if="cardObj.reduceTag" class="col-start-center">
            <span class="f14">春醒优惠</span>
            <span><span class="f12">￥</span><span class="f18">{{ cardObj.reduceTag }}</span></span>
          </div>
          <p v-if="cardObj.reduceTag" class="f18">-</p>
          <div class="ding col-start-center">
            <span class="f14">定金优惠</span>
            <span><span class="f12">￥</span><span class="f18">{{ cardObj.deposit }}</span></span>
          </div>
        </div>
        <p class="p2 f18 fw6">
          <span class="f16">￥</span>
          <span :class="[(cardObj.deliveryPriceTag && cardObj.deliveryPriceTag > 9999) ? 'f24' : 'f26']">{{ cardObj.deliveryPriceTag }}</span>
        </p>
      </div>
      <div v-if="cardObj.deliveryPriceTag" class="dd f18 fw6 row-center-center">{{ cardObj.depositTag }}</div>
      <div v-if="perSaleEndTime > 0 && perSaleStartTime === 0 && !cardObj.deliveryPriceTag" class="dd f18 fw6 row-center-center">
        不支持定金抵扣
      </div>
    </div>
    <div class="goods-info col-between-start">
      <div>
        <div class="goods-name f30 fw6 ellipsis">{{ cardObj.goodsName }}</div>
      <div class="invalid-time f20" v-if="cardObj.hint">{{ cardObj.hint }}</div>
      <div class="label-line" v-if="cardObj.productTags.length || cardObj.discountTag">
        <div class="label-model f20 fw6"
          :style="`color: ${shopStyle.goodsLabel.textColor}; background: linear-gradient(90deg, ${shopStyle.goodsLabel.bgTopColor} 0%, ${shopStyle.goodsLabel.bgBottomColor} 100%);`"
          v-for="item, index in cardObj.productTags" :key="index">
          {{ item }}
        </div>

        <div v-if="cardObj.goodsType != 14 && cardObj.discountTag && cardObj.goodsName.indexOf('小班课') === -1" class="label-model f20 fw6"
          :style="`color: ${shopStyle.goodsLabel.textColor}; background: linear-gradient(90deg, ${shopStyle.goodsLabel.bgTopColor} 0%, ${shopStyle.goodsLabel.bgBottomColor} 100%);`"
          >
          {{ cardObj.discountTag }}
        </div>

      </div>

      <div v-if="cardObj.isFeeInterest && appType() !== 'mini'" class="send f20 fw6"
        :style="`color: ${shopStyle.promoLabel.textColor}; background: linear-gradient(90deg, ${shopStyle.promoLabel.bgTopColor} 0%, ${shopStyle.promoLabel.bgBottomColor} 100%);`">
        {{ cardObj.isFeeInterest ? '3期免息' : '' }}</div>
      <div v-if="cardObj.plugTags" class="send f20 fw6"
        :style="`color: ${shopStyle.promoLabel.textColor}; background: linear-gradient(90deg, ${shopStyle.promoLabel.bgTopColor} 0%, ${shopStyle.promoLabel.bgBottomColor} 100%);`">
        {{ cardObj.plugTags }}</div>
      </div>

      <div>
        <div class="last-line">
        <div class="price-line">
          <span v-if="cardObj.deliveryPriceTag" class="dl f18 fw6">{{ cardObj.goodsStatus === 'PER_SALE' ? '实付价' : '尾款价'}}</span>
          <span class="dl f18 fw6">¥</span>
          <span class="price f32 fw6">{{ (cardObj.goodsStatus === 'PER_SALE' && cardObj.deliveryPriceTag) ? cardObj.deliveryPriceTag : cardObj.salePrice }}<span v-if="cardObj.showStart">起</span></span>
          <span v-if="cardObj.displayOriginPrice" class="ori-price f20">¥{{ cardObj.originPrice }}</span>
        </div>
      </div>
      <div class="row-end-center">
        <div :class="['button', 'f24', 'fw6', 'row-center-center']" :style="buttonColor" @click="buyCard">
          {{ buttonText }}
        </div>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import buyStatus from '../config'
import { appType } from '@/lib/appMethod'
export default {
  data() {
    return {

    }
  },
  props: {
    shopStyle: {
      value: {
        promoLabel: {},
        goodsLabel: {}
      },
      type: Object
    },
    cardObj: {
      type: Object
    },
    status: {
      type: String
    },
    activityStatus: {
      type: String
    },
    perSaleEndTime: {
      type: Number
    },
    perSaleStartTime: {
      type: Number
    }
  },
  computed: {
    buttonText() {
      if (this.status === 'PER_SALE') {
        return `付定金 ￥${this.cardObj.deposit}`
      }
      if (this.status === 'CAN_BUY' && this.cardObj.deliveryPriceTag) {
        return '马上付尾款'
      }
      if (buyStatus[this.status]) {
        return buyStatus[this.status].name
      }

      return this.activityStatus === 'notin' ? '待开启' : this.activityStatus === 'end' ? '已结束' : '马上抢'
    },
    buttonColor() {
      if (!this.shopStyle.buyButtonFailure) return ''
      if (this.status === 'PER_SALE') {
        return `color: ${this.shopStyle.buyButtonEffective.textColor}; background: linear-gradient(180deg, ${this.shopStyle.buyButtonEffective.bgTopColor} 0%, ${this.shopStyle.buyButtonEffective.bgBottomColor} 100%)`
      }
      if (this.status && (this.status != 'CAN_BUY')) {
        return `color: ${this.shopStyle.buyButtonFailure.textColor}; background: linear-gradient(180deg, ${this.shopStyle.buyButtonFailure.bgTopColor} 0%, ${this.shopStyle.buyButtonFailure.bgBottomColor} 100%)`
      }
      return ['notin', 'end'].includes(this.activityStatus) ? `color: ${this.shopStyle.buyButtonFailure.textColor}; background: linear-gradient(180deg, ${this.shopStyle.buyButtonFailure.bgTopColor} 0%, ${this.shopStyle.buyButtonFailure.bgBottomColor} 100%)`
        : `color: ${this.shopStyle.buyButtonEffective.textColor}; background: linear-gradient(180deg, ${this.shopStyle.buyButtonEffective.bgTopColor} 0%, ${this.shopStyle.buyButtonEffective.bgBottomColor} 100%)`
    }
  },
  methods: {
    appType,
    buyCard() {
      this.$emit('buyCard')
    }
  }
};
</script>
<style lang="less">
.sb-bag {
  width: 100%;
  padding: 12px;
  background: #fff;
  display: flex;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;

  &:last-of-type {
    margin: 0;
  }

  .goods-img {
    width: 318px;
    height: 280px;
    margin-right: 20px;
    background-image: url(https://img.chaolu.com.cn/ACT/spring2024/biank.png);
    background-size: 100% 100%;
    position: relative;
    img {
      width: 247px;
      height: 150px;
      border-radius: 16px;
      display: block;
      &.up {
        margin: -36px 0 0;
      }
    }
    .cc {
      width: 100%;
      height: 63px;
      background-image: url(https://img.chaolu.com.cn/ACT/spring2024/card-bg.png);
      background-size: 100% 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      color: #fff;
      .p2 {
        width: 90px;
        text-align: right;
        color: #000;
        position: absolute;
        right: 4px;
        bottom: 2px;
        text-shadow: #fff 1px 0 0, #fff 0 1px 0, #fff -1px 0 0, #fff 0 -1px 0;
      }
      .b1 {
        width: 224px;
        position: absolute;
        left: 0;
        bottom: 2px;
        div {
          width: 32%;
        }

        p {
          margin: 20px 0 0;
        }
        // .le {
        //   margin-left: 8px;
        // }
        span, p {
          display: inline-flex;
          align-items: baseline;
          text-shadow: rgba(48, 112, 16, 1) 0.6px 0 0, rgba(48, 112, 16, 1) 0 .6px 0, rgba(48, 112, 16, 1) -.6px 0 0, rgba(48, 112, 16, 1) 0 -.6px 0;
          // -webkit-text-stroke: 1px rgba(48, 112, 16, 1);
        }
      }
    }
  }

  .goods-info {
    padding: 4px 0 0;
    position: relative;
    overflow: hidden;
    flex: 1;

    .goods-name {
      color: #242831;
    }

    .last-line {
      margin: 0 0 24px;
    }
    .button {
        width: 200px;
        height: 56px;
        background: linear-gradient(0deg, #f35947 0%, #fba27b 100%);
        border-radius: 28px;
        color: #fff;
      }

      .gray {
        color: #696c6f;
        background: linear-gradient(0deg, #d7d7d7 0%, #cfd0d2 100%);
      }
  }

  .invalid-time {
    color: #242831;
    margin-top: 4px;
  }

  .label-line {
    white-space: nowrap;
    margin-top: 0px;

    .label-model {
      padding: 0 10px;
      height: 30px;
      background: linear-gradient(90deg, #FFE8B5 0%, #FFD7C3 100%);
      border-radius: 4px;
      color: #B12E1D;
      margin-right: 10px;
      line-height: 30px;
      display: inline-flex;
      max-width: 100%;
      overflow: hidden;
    }
  }

  .price-line {
    line-height: 1;

    .dl {
      color: #EE4832;
      margin-right: 4px;
    }

    .price {
      color: #EE4832;
      margin-right: 9px;
      position: relative;
      top: 2px;
    }

    .ori-price {
      text-decoration: line-through;
      color: #6C727A;
    }
  }

  .send {
    width: fit-content;
    border-radius: 4px;
    background: linear-gradient(90deg, #3889F0 0%, #A26BDC 99%);
    padding: 0 10px;
    line-height: 30px;
    color: #fff;
    margin: 12px 0 0;
  }

  .dd {
      width: 213px;
      height: 28px;
      background-image: url(https://img.chaolu.com.cn/ACT/spring2024/no-bg.png);
      background-size: 100% 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
    }
}
</style>
