<template>
  <div>

  </div>
</template>
<script>
import { newAppBack, initBack, appOpenWeb, appPaySuccess, gotoOrderList, gotoAppPage } from '@/lib/appMethod'
// import { throttle } from '@/lib/utils'

import navBar from '@/components/nav-bar/nav-bar'
import commonShare from '@/components/commonShare'
import styleOneCard from './components/style-one-card2'
// import styleTwoCard from './components/style-two-card'
// import styleThreeCard from './components/style-three-card'
import countDown from './components/count-down'
import recommendedGoods from './components/recommended-goods'
import payPopupCard from './components/pay-popup-card'

import userMixin from '@/mixin/userMixin'
import headerMixin from '@/mixin/headerMixin'

import jump from './jump'

import wx from 'weixin-js-sdk'
const webUrl = `${window.location.origin}/#/superdeer-activity/double11-2023/final-payment`

export default {
  components: {
    navBar,
    commonShare,
    styleOneCard,
    // styleTwoCard,
    // styleThreeCard,
    countDown,
    recommendedGoods,
    payPopupCard
  },
  mixins: [userMixin, headerMixin],
  data() {
    return {
      shopId: '', // 活动id
      AI: { // 活动总信息
        activityTrailer: {}, // 预告页
        partitions: {},
        shopStyle: {}
      },

      barHeight: 0,
      navHeight: 0,

      tabIndex: 0, // 分栏的下标
      groupIndex: 0, // 分组的下标
      shareParams: {
        title: '超鹿双11-提前付尾款',
        miniImage: 'https://img.chaolu.com.cn/ACT/db11-2023/wk-mini.png',
        path: '',
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },
      userDetail: {}, //分享的用户信息
      inviteUserInfo: {}, // 邀请人信息

      isShareShow: false,
      isRuleShow: false,
      isItemSelectShow: false, // 规格选择弹窗
      isItemConfirmShow: false, // 规格确认弹窗
      itemIndex: undefined, // 规格下标

      buyGoods: {}, // 当前购买的商品 包含多个规格
      buyItem: {}, // 当前购买的规格
      showPayPopup: false, // 权益卡选择优惠券弹窗
      payData: {}, // 支付参数
      payUrl: '', // 支付回跳链接

      activityStatus: '', // 活动状态

      itemStatusMap: {}, // 当前商品下多规格状态
      curRule: '', // 当前规则 可能是总规则 可能是菜单规则

      topArr: [], // 若存在分组 各个分组的offset集合
      isClickScroll: false,

      isDingShow: false,
      dingList: [],
      queryCityId: ''
    }
  },
  computed: {
    // 当前菜单下的商品 tab
    curGoods() {
      let c = this.AI.partitions.subfieldsInfos ? this.AI.partitions.subfieldsInfos[this.tabIndex] : { groupInfos: [], promotionalInfo: {} }
      // 处理 卡片上 标签问题 逗号隔开的字符改为 数组
      c.groupInfos.map((group) => group.goodsInfos.map((good) => good.productTags = Array.isArray(good.productTags) ? good.productTags : good.productTags ? good.productTags.split(',') : []))
      return c
    },
    // 多规格情况下 规格的list
    itemList() {
      return this.buyGoods.itemInfos ? this.buyGoods.itemInfos : []
    },
    // 判断人群 是否显示
    showWithCrow() {
      return (good) => {
        // 没有showWithCrow或者有的情况下状态可购买时显示 没有saleCity或者有但包含当前所在城市时显示
        return (!good.showWithCrow || (good.showWithCrow && (good.goodsStatus === 'CAN_BUY' || good.goodsStatus === 'PER_SALE'))) && (!good.saleCity || (good.saleCity.includes(this.cityId)))
      }
    },
    isInterestFree() {
      console.log(this.shareParams.path);
      return this.shareParams.path.indexOf('-free') > -1
    }
  },
  async created() {
    initBack();


    // this.$toast('活动已结束')
    // setTimeout(() => {
    //   this.newAppBack()
    // }, 2000)
    // return



    await this.$getAllInfo(['userId', 'cityId'])

    const q = this.$route.query
    // 若是教练邀请 获取教练id
    this.inviteTeacherId = q.teacherId || q.inviteTeacherId
    this.inviteUserId = q.inviteUserId
    // 来源
    this.from = q.from
    this.queryCityId = q.shopCityId || this.cityId // 区别于mixin里的cityId
    // 是否进入预售模式
    this.preSaleModel = q.preSaleModel ? true : false

    // 本次商城的id
    this.shopId = 10027

    // 分享的链接
    this.shareParams.path = `${webUrl}&id=${this.shopId}`

    // 获取活动总信息
    const r = await this.getActivity()
    if (r === '0') return

    // 海报的二维码
    this.initQr()

    // 获取绑定关系
    this.getBindInfo()

    // 小程序卡片分享
    if (this.AI.shopStyle.shareStatus) {
      this.miniShare()
    }


    // 支付成功回调 小程序购买成功提示
    appPaySuccess().then(() => {
      this.paySuccessCallback()
    })
  },
  mounted() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible' && !this.AI.activityTrailer) {
        setTimeout(() => {
          // this.getGoodStatus()
          this.getActivity()
        }, 500)
      }
    })
  },
  methods: {
    newAppBack,
    previewFinish() {
      this.getActivity()
    },
    setBarHeight(h) {
      this.barHeight = h || 0
    },
    setTabIndex(v) {
      this.tabIndex = v
      // this.getGoodStatus()
      // 是否有滚动
      this.setScroll()
    },
    toPath(path) {
      if (this.AI.diffStartTime > 0) return

      const p = path.split('?')[0]
      const p1 = path.split('?')[1] ? `&${path.split('?')[1]}` : ''
      const hasDomain = p.indexOf('http') > -1
      if (this.appTypeStr === 'mini') {
        wx.miniProgram.navigateTo({
          url: `/pages/webView/index?webUrl=${hasDomain ? `${p}${p1}` : (window.location.origin + '/#' + `${p}${p1}`)}`,
        })
      } else {
        hasDomain ? appOpenWeb('', path) : (this.$router.push(p))
      }
    },
    toAppPath(obj) {
      if (!obj) return
      jump(obj)
    },
    gotoOrderList,
    gotoOrderDetail(no) {
      gotoAppPage(7, { payOrderNo: no })
    },

    async openItemSelect() {
      if (this.activityStatus != 'in') {
        this.$toast('活动还没开始哦~')
        return
      }
      await this.getItemStatus()
      this.isItemSelectShow = true
    },
    next() {
      this.isItemConfirmShow = this.itemIndex != undefined ? true : false
    },
    chooseItem(i, status) {
      if (status !== 'CAN_BUY') return
      this.itemIndex = i
    },
    nextConfirm() {
      if (this.itemIndex === undefined) return

      this.isItemConfirmShow = false
      this.isItemSelectShow = false

      this.buyCard(this.buyGoods)
    },
    checkCanBuy(info) {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      return this.$axios.post(this.baseURLApp + '/activityShopOrder/checkBuy', {
        goodsId: info.id,
        goodsItemId: this.buyItem.id,
        shopId: this.shopId,
        userId: this.userId,
      }).then((r) => {
        this.$toast.clear()
        return r.data
      }).catch(() => {
        this.$toast.clear()
        return 0
      })
    },
    buyFinal(item) {
      this.AI.partitions.subfieldsInfos.map((sub) => {
        sub.groupInfos.map((group) => {
          group.goodsInfos.map((goods) => {
            if (goods.id === item.goodsId) {
              goods.useDepositOrderNo = item.useDepositOrderNo
              this.buyCard(goods)
            }
          })
        })
      })

    },
    async buyCard(item) {
      // item可能来自推荐弹窗组件item.isRecommended:true
      // 推荐的商品可能不是当前分组下的商品 所以goodsStatusMap无法使用
console.log(item, 'llll');
      this.buyGoods = item
      this.buyItem = this.itemIndex === undefined ? item.itemInfos[0] : item.itemInfos[this.itemIndex]

      // 如果由推荐商品的弹窗触发的 必然是能购买的
      if (!item.isRecommended && (item.goodsStatus !== 'CAN_BUY' && item.goodsStatus !== 'PER_SALE')) {
        this.$toast('不符合购买条件')
        return
      }
      if (this.itemIndex === undefined && item.goodsName.indexOf('自助') > -1 && (item.goodsStatus === 'PER_SALE' || (item.goodsStatus === 'CAN_BUY' && !item.deliveryPriceTag))) {

        this.itemIndex = 0
        this.isItemConfirmShow = true
        return
      }

      // // 各个数量大于1 打开规格选项弹窗
      // if (item.itemInfos.length > 1 && this.itemIndex === undefined) {
      //   this.openItemSelect()
      //   return
      // }

      this.itemIndex = undefined

      const res = await this.checkCanBuy(item)

      if (!res) {
        this.$toast('无法购买')
        return
      }

      const obj = JSON.parse(JSON.stringify(item))
      item.useDepositOrderNo = undefined
      this.payAction(obj)
    },
    payAction(info) {
      console.log(info, 'skjdfk');
      // 如果是教练分享单个商品的情况 支付完回到页面还是只能看到单商品的弹窗 因此回调链接加上goodsId
      const goodsId = info.isRecommended ? info.id : ''

      let url = `/pages/webView/index?webUrl=${this.shareParams.path}&userId=1&goodsId=${goodsId}`
      let payData = {
        subject: this.buyItem.productName,
        extendsJson: {
          appVersion: '2.1.11',
          shopId: this.shopId,
          activityShopGoodsId: this.buyGoods.id + '',
          activityShopGoodsItemId: this.buyItem.id,
          from: this.from,
          deliveryPriceTag: info.deliveryPriceTag,
          goodsType: info.goodsType,
          useDepositOrderNo: info.useDepositOrderNo
          // inviteUserId: this.inviteId,
        },
        totalAmount: this.buyItem.salePrice + '',
        venueId: '31',
        goodsNum: [12, 22].includes(info.goodsType) ? '1' : undefined,
        isApp: '1',
        type: info.goodsType, // 礼品卡12  课包10
        userId: this.userId,
        token: this.token,
        extendsType: '4',
        mouldId: this.buyItem.productId,
        whetherPrivacy: 0,
        originalPriceStr: '',
        title: this.appTypeStr === 'mini' ? '商品名称' : this.buyItem.productName,
      }
      if (this.appTypeStr === 'mini') {
        payData.wxPayType = 'applet'
      } else {
        appPaySuccess().then(() => {
          this.paySuccessCallback()
        })
      }

      // 权益卡 弹个选择优惠券的窗
      // if ([19, 14].includes(info.goodsType) && this.shopId == 81) {
      //   this.showPayPopup = true
      //   this.payData = payData
      //   this.payUrl = url
      // } else {
      //   appPay(payData, url);
      // }

      this.showPayPopup = true
      this.payData = payData
      this.payUrl = url

    },

    async timeFinish() {
      await this.getActivity()
    },
    getBindInfo() {
      this.$axios
        .post(this.baseURLApp + "/userDistribute/bindUser", {
          hasWx: true,
          activityNo: this.shopId, // shopId就是老接口里的activityNo
          userId: this.userId,
          saleTeacherId: this.inviteTeacherId,
          saleUserId: this.inviteUserId
        })
        .then((res) => {
          const d = res.data;
          this.inviteUserInfo = d || {}
          this.inviteTeacherId = d && d.shareTeacherId
        });
    },

    getActivity() {
      return this.$axios.post(`${this.baseURLApp}/double11/2023/getCheatingShop`, {
        shopId: this.shopId,
        userId: this.userId,
        cityId: this.queryCityId,
        interestFreeModel: false,
        preSaleModel: this.preSaleModel // 强行进入预售模式
      }).then(res => {

        res.data.partitions.subfieldsInfos.map((sub) => {
          if (sub.showGroupMenu && sub.groupInfos.length > 1) {
            sub.groupInfos.map((group, groupIndex) => {
              let canShow = false
              group.goodsInfos.map((goods) => {
                if (!goods.saleCity || (goods.saleCity && goods.saleCity.includes(this.cityId))) {
                  canShow = true
                }
              })
              this.$set(group, 'canShow', canShow)
            })
          }
        })

        // 活动总信息
        this.AI = res.data
        if (!this.AI.activityTrailer) {
          // 设置活动状态
          this.activityStatus = ((this.AI.diffStartTime > 0 && this.AI.perSaleStartTime > 0) || (this.AI.diffStartTime > 0 && this.AI.perSaleEndTime === 0)) ? 'notin' : (this.AI.diffEndTime > 0 || ths.AI.perSaleEndTime > 0) ? 'in' : 'end'
          // 设置页面标题
          document.title = '超鹿双11-提前付尾款'
          // 设置小程序卡片分享内容
          this.shareParams.title = '超鹿双11-提前付尾款'

          // 兜底
          if (!this.AI.shopStyle.groupTitleImg) this.AI.shopStyle.groupTitleImg = 'https://img.chaolu.com.cn/ACT/anniversary-2023/coverbg.png'
          // 预加载海报图
          if (this.AI.shareImg) {
            let img = new Image()
            img.src = this.AI.shareImg
            img.onload = () => { }
          }

          this.setTabIndex(0)
          // 获取滚动元素高度
          this.getHeight()
        }
        this.getDingList()
      }).catch((res) => {
        this.$toast(res.msg)
        setTimeout(() => {
          this.newAppBack()
        }, 2000)
      })
    },

    getDingList() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      return this.$axios
        .post(this.baseURLApp + "/deposit/queryCheatingDepositOrder", {
          userId: this.userId,
          shopId: this.shopId
        })
        .then((res) => {
          this.dingList = res.data.list || []
          this.$toast.clear()
        }).catch(() => {
          this.$toast.clear()
        })
    },
    async openDing() {
      await this.getDingList()
      this.isDingShow = true
    },
    // 获取当前菜单tab下商品的id集合 单独获取商品的购买状态 结果用id匹配 {34: 'CAN_BUY'}
    // getGoodStatus() {

    //   let idArr = []
    //   this.curGoods.groupInfos.map((group) => {
    //     group.goodsInfos.map((goods) => {
    //       idArr.push(goods.id)
    //     })
    //   })

    //   this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodsStatus`, {
    //     goodsId: idArr,
    //     shopId: this.shopId,
    //     userId: this.userId,
    //   }).then(res => {
    //     this.goodsStatusMap = res.data.goodsStatusMap
    //     this.$toast.clear()

    //     // 判断 分栏底下的商品 是否因为人群关系 全部隐藏 此时 需要隐藏整个分栏
    //     this.curGoods.groupInfos.map((group) => {
    //       let show = false
    //       group.goodsInfos.map((goods) => {
    //         if (!goods.showWithCrow || (goods.showWithCrow && this.goodsStatusMap[goods.id] === 'CAN_BUY')) show = true
    //       })

    //       this.$set(group, 'show', show)
    //     })

    //   })
    // },
    // 获取当前购买商品的规格id集合 结果用id匹配 {34: 'CAN_BUY'}
    getItemStatus() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      const idArr = this.buyGoods.itemInfos.map((item) => item.id)
      return this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodsItemStatus`, {
        goodItems: idArr,
        goodsId: this.buyGoods.id,
        shopId: this.shopId,
        userId: this.userId,
      }).then(res => {
        this.itemStatusMap = res.data.goodsStatusMap
        this.$toast.clear()
      })
    },

    paySuccessCallback() {
      console.log('suc')
      this.$toast('支付成功')
      setTimeout(() => {
        // this.getGoodStatus()
        this.getActivity()
      }, 500)
    },
    initQr() {
      this.$axios
        .post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
          token: this.token,
          userId: this.userId,
          width: 240,
          height: 240,
          webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
          scene: JSON.stringify({
            source: `activity_${this.shopId}`,
            id: this.shopId,
            webUrl,
          }),
        })
        .then((res) => {
          this.userDetail = res.data
        });
    },
    miniShare() {
      wx.miniProgram.postMessage({
        data: {
          type: 'share',
          shareImage: this.shareParams.miniImage,
          shareTitle: this.shareParams.title,
          shareUrl: `/pages/webView/index?webUrl=${this.shareParams.path}`,
        },
      })
    },
    openRule(isTotalRule) {
      this.curRule = isTotalRule ? this.AI.shopStyle.ruleContent : this.curGoods.saleRule
      this.isRuleShow = true
    },
    close() {
      this.isItemSelectShow = false
      this.itemIndex = undefined
    },
    getHeight() {
      this.$nextTick(() => {
        this.navHeight = this.$refs.nav ? this.$refs.nav.clientHeight : 0
      })
    },
    toGroup(el, index) {
      this.groupIndex = index
      const ref = this.$refs[el]

      this.isClickScroll = true
      setTimeout(() => {
        this.isClickScroll = false
      }, 1000)
      window.scrollTo({
        left: 0,
        top: ref[0].offsetTop - (this.barHeight + this.navHeight + 45) - 10,
        behavior: 'smooth'
      })
    },
    setScroll() {
      this.topArr = []
      this.$nextTick(() => {
        // 计算各个group的offsetTop
        this.curGoods.groupInfos.map((group) => {
          let top = this.$refs[`group${group.id}`][0].offsetTop - (this.barHeight + this.navHeight + 45) - 10
          this.topArr.push(top)
        })

        if (this.curGoods.showGroupMenu && this.curGoods.groupInfos.length > 1) {
          window.addEventListener('scroll', this.handleScroll, true)
        } else {
          window.removeEventListener('scroll', this.handleScroll, true)
        }
      })
    },
    handleScroll(e) {
      if (e.target.nodeName !== '#document' || this.isClickScroll) return
      let top = document.documentElement.scrollTop || document.body.scrollTop
      for (let i = 0; i < this.topArr.length; i++) {
        if (top < this.topArr[i + 1]) {
          this.groupIndex = i
          break;
        } else {
          this.groupIndex = this.topArr.length - 1
        }
      }
    },
    hexToRgba(hex, opacity) {
      if (!hex) return ''
      return `rgba(${parseInt('0x' + hex.slice(1, 3))}, ${parseInt('0x' + hex.slice(3, 5))}, ${parseInt('0x' + hex.slice(5, 7))}, ${opacity})`
    },
    recommendedClose() {
      // this.getGoodStatus()
      this.getActivity()
    }
  },
}
</script>
<style lang="less" scoped>
.marketing-preview {
  min-height: 100vh;
}

.double11-2023 {
  min-height: 100vh;
  background: #FF9A49;
  padding-bottom: 80px;

  div {
    box-sizing: border-box;
  }

  img {
    width: 100%;
  }

  .template-content {
    width: 100%;
  }

  .background {
    position: relative;
    width: 100%;

    .head-img {
      display: block;
      min-height: 30px;
    }

    .top-button {
      position: absolute;
      right: 0;
      top: 238px;
      width: 88px;
      z-index: 99;

      .icon {
        width: 88px;
        height: 88px;
        object-fit: contain;
        margin-bottom: 32px;
      }

      .button {
        line-height: 56px;
        background: rgba(36, 40, 49, 0.3);
        border-radius: 40px 0 0 40px;
        color: #fff;
        width: 100%;
        text-align: center;
        margin-bottom: 32px;
        padding-left: 6px;
      }
    }

    .float-icon {
      position: fixed;
      right: 0;
      top: 440px;
      width: 144px;
      z-index: 99;
    }
  }

  .new-ad {
    width: 100%;
    margin: 16px 0 0;
  }

  .invite-block {
    width: 686px;
    height: 104px;
    margin: 20px 32px 0;
    background: url(https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_bg_invite.png);
    background-size: 100% 100%;
    padding: 0 36px;
    color: #EEEEEE;

    .invite-head {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      overflow: hidden;
      background: #f5f5f5;
      flex-shrink: 0;
      margin-right: 20px;

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }


  .banner-block {
    width: 100%;
    margin: 30px 0 0;
    padding: 0 32px;

    .small-banner {
      width: 333px;
    }
  }

  .goods-title {
    width: 427px;
    display: block;
    margin: 70px auto 12px;
  }

  .nav-title {
    width: 686px;
    margin: 70px 0 20px;
  }

  .nav {
    width: 100%;
    position: relative;
    padding: 16px 32px 8px;

    .tm {
      max-width: 335px;
      min-width: 333px;
      flex: 1;
      margin-bottom: 16px;
      background: linear-gradient(180deg, #FFFFFF 0%, #FFE9E3 100%);
      border-radius: 12px;
      height: 100px;
      padding-left: 26px;
      color: #383E43;
      position: relative;
      overflow: hidden;

      &.small {
        max-width: 218px;
        min-width: 216px;
      }

      &.active {
        color: #9B270C;
        background: linear-gradient(180deg, #FFFED8 0%, #FFE293 100%);
      }

      .ii {
        transform: scale(0.8);
        transform-origin: 0 50%;
        white-space: nowrap
      }
    }

  }

  .title-line {
    width: 100%;
    color: #fff;
    padding: 20px 32px 28px;

    .arrow {
      width: 21px;
    }

    .ss {
      margin-right: 4px;
    }
  }

  .end {
    text-align: center;
    color: #fff;
    position: relative;
    margin-top: 40px;

    &::after,
    &::before {
      content: '';
      vertical-align: middle;
      display: inline-block;
      width: 60px;
      height: 1px;
      background: #fff;
    }

    &::after {
      margin: -2px 0 0 29px;
    }

    &::before {
      margin: -2px 29px 0 0;
    }
  }

  .group-bar {
    width: 750px;
    padding: 8px 32px 18px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.2);
    margin: -8px 0 36px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    .item {
      width: 20%;
      color: #fff;
      border-radius: 20px;
      padding: 12px 0;

      &.active {
        background: linear-gradient(0deg, #FFFFFF 0%, #FFE9E3 100%);
        color: #F9624E;
      }
    }
  }

  .good-block {
    width: 100%;
    padding: 0 32px;

    .ad-group {
      .ad-item {
        width: 100%;
        margin: 0 0 32px;
      }
    }

    .good-group {
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.2);
      padding: 24px 16px;
      margin-bottom: 40px;
      min-height: 280px;
      position: relative;

      &.has-title {
        padding: 74px 16px 24px;
      }

      &.only-img {
        background: none;
        padding-left: 0;
        padding-right: 0;
      }

      .two-img {
        width: 50%;
      }

      .three-img {
        width: 33.33%;
      }
    }

    .group-name {
      position: absolute;
      top: -10px;
      left: 0;
      height: 60px;
      width: 50%;
      background-size: 100% 100%;
      color: #68413C;
      padding: 0 0 0 24px;
      overflow: hidden;
    }
  }

  .pop-rule {
    border-radius: 24px 24px 0 0;
    color: #242831;

    .pop-rule-title {
      height: 140px;
      text-align: center;
      line-height: 130px;
      position: relative;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(246, 103, 67, 0.3) 100%);

      .pop-rule-close {
        position: absolute;
        right: 32px;
        top: 38px;
        width: 44px;
        height: 44px;
        line-height: 44px;
        border-radius: 50%;
        background: rgba(39, 40, 49, 0.2);
      }
    }

    .pop-rule-content {
      padding: 0 52px;
      max-height: 600px;
      overflow: auto;
      padding-bottom: 60px;
      white-space: pre-line;
    }
  }

  .pop-item {
    border-radius: 24px 24px 0 0;
    color: #242831;

    .buy-title {
      border-bottom: 1px solid #EEEEEE;
      height: 97px;
      line-height: 97px;
      padding: 0 32px;
      text-align: center;
      position: relative;
    }

    .buy-close {
      position: absolute;
      right: 32px;
      top: 50%;
      transform: translateY(-50%);

    }

    .cbox {
      padding: 37px 32px 0;
      border-bottom: 1px solid #eee
    }

    .buy-tips {
      color: #9AA1A9;
      margin: 4px 0 22px;
    }

    .goods-line {
      display: flex;
      margin: 22px 0 60px;

      .goods-img {
        width: 256px;

        img {
          width: 100%;
          border-radius: 16px;
          display: block;
        }
      }

      .goods-info {
        flex: 1;
        margin-left: 30px;

        .goods-name {
          color: #242831;
        }

        .time {
          color: #242831;
          margin: 12px 0 20px;
          line-height: 1;
        }

        .labels {
          margin: 0 0 15px;

          .label {
            height: 30px;
            background: linear-gradient(90deg, #FFE8B5 0%, #FFD7C3 100%);
            padding: 0 13px;
            margin: 0 12px 0 0;
            color: #B12E1D;
            border-radius: 4px;
          }
        }

        .price-line {
          margin-top: 8px;
          line-height: 1;

          .dl {
            color: #EE4832;
            margin-right: 4px;
          }

          .price {
            color: #EE4832;
            margin-right: 9px;
            position: relative;
            top: 2px;
          }

          .ori-price {
            text-decoration: line-through;
            color: #6C727A;
          }
        }
      }
    }

    .city-list {
      padding: 0 0 32px;

      .city-model {
        margin-top: 24px;
        height: 128px;
        background: #F5F5F5;
        border-radius: 8px;
        padding: 0 32px;

        &.disabled {
          color: #9AA1A9;
        }

        &.active {
          border: 4px solid #242831;

          .checkbox {
            width: 48px;
            height: 48px;
            background: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/spring2023/check.png);
            background-size: 100% 100%;
            border: none;
            margin: 0;
          }
        }

        .checkbox {
          width: 32px;
          height: 32px;
          border: 1px solid #DDD;
          border-radius: 50%;
          margin-right: 8px;
        }

        .has-buy {
          width: 102px;
        }
      }


    }

    .next {
      line-height: 96px;
      background: #FFDE00;
      border-radius: 8px;
      margin: 34px 32px 0;
      text-align: center;
      margin-bottom: 34px;

      &.grey {
        filter: grayscale(1);
      }
    }
  }

  .pop-sure {
    width: 590px;
    border-radius: 12px;
    padding: 50px 40px;

    .sure-title {
      color: #242831;
      text-align: center;
    }

    .sure-content {
      color: #3C454E;
      line-height: 1.6;
      margin: 36px 0 48px;
      text-align: center;

      span {
        color: #F03C18;
      }
    }

    .sure-sub {
      border-radius: 8px;
      background: #FFDE00;
      color: #000;
      line-height: 96px;
      text-align: center;
    }
  }

  @bei: 1px;

  .canvascss {
    position: fixed;
    bottom: -200%;
    // top: 0;
    width: @bei*600;
    height: @bei*1066;
    margin: 0 auto;
    box-sizing: border-box;

    .canvascss_bg {
      width: 100%;
      height: 100%;
    }



    .canvascss_left {
      position: absolute;
      left: @bei*26;
      bottom: @bei*30;
      z-index: 1;
      width: @bei*100;
      height: @bei*100;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .canvascss_name {
      position: absolute;
      left: @bei*150;
      bottom: @bei*110;
      z-index: 1;
      color: #fff;
      width: @bei*252;
    }

    .canvascss_right {
      position: absolute;
      right: @bei*34;
      bottom: @bei*28;
      width: @bei*124;
      height: @bei*124;
      overflow: hidden;
      //background: url(https://img.chaolu.com.cn/ACT/anniversary-2023/border.png);
      background-size: 100% 100%;
      padding: @bei*12;

      img {
        border-radius: @bei*8;
        width: 100%;
        height: 100%;
      }
    }

  }

  /deep/.canvas-box {
    padding: 0 40px !important;
    box-sizing: border-box;

    .create-img {
      width: 100% !important;
    }
  }

  .pop {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background: #F5F5F5;
    padding: 0 32px;

    .pop-title {

      font-weight: bold;
      font-size: 36px;
      text-align: center;
      height: 120px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .ts {
        position: relative;
        transform-style: preserve-3d;
        display: inline-block;

        &::after {
          content: '';
          position: absolute;
          left: -6px;
          bottom: -2px;
          width: 154px;
          height: 18px;
          background: linear-gradient(270deg, rgba(255, 232, 206, 0) 0%, #FA6B76 100%);
          border-radius: 38px;
          transform: translateZ(-10px);
          z-index: -1;
        }
      }

      .pop-close {
        position: absolute;
        right: 32px;
        top: 38px;
        width: 44px;
        height: 44px;
        line-height: 44px;
        border-radius: 50%;
        background: rgba(39, 40, 49, 0.2);
        font-size: 28px;
        text-align: center;
      }
    }

    .records {
      max-height: 1050px;
      min-height: 500px;
      overflow: auto;
      margin: 20px 0 0;

      .item {
        background-color: #fff;
        padding: 24px 32px;
        margin: 0 0 20px;
        border-radius: 14px;

        .t {
          padding: 0 0 24px;
          border-bottom: 1px solid #EEEEEE;
          div:nth-child(1) {
            margin: 0 16px 0 0;
          }
          .p2 {
            margin: 18px 0 0;
          }
        }

        .b {
          padding: 24px 0 0;

          .before {
            color: #EE4832;
          }

          .button {
            width: 200px;
            height: 60px;
            background: linear-gradient(0deg, #F35947 0%, #FBA27B 100%);
            border-radius: 30px;
            color: #fff;
            margin: 0 0 0 22px;

            &.black {
              background: linear-gradient(0deg, #242831 0%, #454D5E 100%);
            }
          }
        }
      }
    }

    .empty {
      margin: 120px 0 160px;
      text-align: center;
      color: #6C727A;
      img {
        width: 320px;
        margin: 0 0 12px;
      }
    }

    .ding-tip {
      margin: 36px auto 72px;
      text-align: center;

      span {
        color: #267DFF;
      }
    }
  }
}</style>
