<template>
  <div class="page-rooter-fff" @touchstart.once="autoPlay()" @touchend.once="autoPlay()">
    <nav-bar v-if="appTypeStr !== 'mini'" header-title="超鹿中秋博饼" backColor="#ffffff" :show-back="true" :header-back="newAppBack" />
    <div id="box" class="page-img">
      <div class="box-page">
        <div class="top-button col-start-center">
          <div class="button f24" @click="posterShare">分享</div>
          <div class="button f24" @click="goRule">规则</div>
          <div id="mc_play" :class="'music ' + (playing ? 'on' : 'stop')" @click="playMusic()">
            <audio id="music" ref="music" loop="loop">
              <source src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/mid-autumn2021/music.mp3" type="audio/mpeg" />
            </audio>
          </div>
        </div>

        <div class="bowl-container">
          <div :class="isEnter ? 'bowl inline active1' : 'bowl inline'" id="bowl">
            <div class="dice dice1 dice-position1"></div>
            <div class="dice dice2 dice-position2"></div>
            <div class="dice dice3 dice-position7"></div>
            <div class="dice dice1 dice-position4"></div>
            <div class="dice dice5 dice-position5"></div>
            <div class="dice dice6 dice-position6"></div>
          </div>
        </div>
        <div class="startBtn" @click="gameStart()">
          <img :style="isEnd ? 'filter: grayscale(100%);' : ''" :src="btnImage" alt="" />
          <audio id="soundEffect" ref="soundEffect">
            <source src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/mid-autumn2021/13230.mp3" type="audio/mpeg" />
          </audio>
        </div>
        <div class="number f28">
          剩余博饼券：
          <div>{{ numberOfDraws }}</div>
        </div>

        <div class="task-list">
          <img src="https://img.chaolu.com.cn/ACT/mid-autumn-2023/index/prize.png" alt="" class="ii" />
          <img src="https://img.chaolu.com.cn/ACT/mid-autumn-2023/index/rr.png" alt="" class="ii" />
          <img src="https://img.chaolu.com.cn/ACT/mid-autumn-2024/rule22.png" alt="" class="ii" />

          <div :class="['tab-box', 'row-center-center', activeTab === 2 ? 't2' : '']">
            <div class="tab-item" v-for="item in 2" :key="item" @click="activeTab = item"></div>
          </div>

          <div v-show="activeTab === 1" class="col-start-center">
            <div class="block a col-start-center">
              <div class="p2 f36 puhui">
                已打卡<span class="BebasNeueBold f60"> {{ activityInfo.scheduleNumber > 15 ? 15 : activityInfo.scheduleNumber }}/15 </span>节
              </div>
              <p class="p5 f22">*打卡3节团课得1张博饼券，最多可得5张<br />（仅统计非月卡免费课）</p>
              <div class="progress-box col-center-center">
                <div class="road">
                  <div class="progress" :style="`width: ${(activityInfo.scheduleNumber * 100) / 15}%`"></div>
                </div>
                <div class="dots row-between-center">
                  <div :class="['dot', activityInfo.scheduleNumber > 0 ? 'active' : '']"></div>
                  <div :class="['dot', activityInfo.scheduleNumber >= 3 ? 'active ok' : '']"></div>
                  <div :class="['dot', activityInfo.scheduleNumber >= 6 ? 'active ok' : '']"></div>
                  <div :class="['dot', activityInfo.scheduleNumber >= 9 ? 'active ok' : '']"></div>
                  <div :class="['dot', activityInfo.scheduleNumber >= 12 ? 'active ok' : '']"></div>
                  <div :class="['dot', activityInfo.scheduleNumber >= 15 ? 'active ok' : '']"></div>
                </div>
                <div class="tips f22 fw6 row-between-center">
                  <span>进度</span>
                  <span>3节</span>
                  <span>6节</span>
                  <span>9节</span>
                  <span>12节</span>
                  <span>15节</span>
                </div>
              </div>
              <div @click="goAppoint(3)" class="p3 f40 fw6 row-center-center">去约课</div>
              <p class="p4 f24">打卡时间：9月5日-9月30日</p>
              <div @click="showRecord('scheduleRecords')" class="r-button f22 row-center-center">打卡记录<img src="https://img.chaolu.com.cn/ACT/mid-autumn-2023/icon.png" alt="" /></div>
            </div>
            <div class="block b col-start-center">
              <div class="p2 f36 puhui">
                已打卡<span class="BebasNeueBold f60"> {{ activityInfo.perNumber > 10 ? 10 : activityInfo.perNumber }}/10 </span>节
              </div>
              <p class="p5 f22">*打卡2节私教课得1张博饼券，最多可得5张<br />（打卡课程需签到成功）</p>
              <div class="progress-box col-center-center">
                <div class="road">
                  <div class="progress" :style="`width: ${(activityInfo.perNumber * 100) / 10}%`"></div>
                </div>
                <div class="dots row-between-center">
                  <div :class="['dot', activityInfo.perNumber > 0 ? 'active' : '']"></div>
                  <div :class="['dot', activityInfo.perNumber >= 2 ? 'active ok' : '']"></div>
                  <div :class="['dot', activityInfo.perNumber >= 4 ? 'active ok' : '']"></div>
                  <div :class="['dot', activityInfo.perNumber >= 6 ? 'active ok' : '']"></div>
                  <div :class="['dot', activityInfo.perNumber >= 8 ? 'active ok' : '']"></div>
                  <div :class="['dot', activityInfo.perNumber >= 10 ? 'active ok' : '']"></div>
                </div>
                <div class="tips f22 fw6 row-between-center">
                  <span>进度</span>
                  <span>2节</span>
                  <span>4节</span>
                  <span>6节</span>
                  <span>8节</span>
                  <span>10节</span>
                </div>
              </div>
              <div @click="goAppoint(1)" class="p3 f40 fw6 row-center-center">去约课</div>
              <p class="p4 f24">打卡时间：9月5日-9月30日</p>
              <div @click="showRecord('perRecords')" class="r-button f22 row-center-center">打卡记录<img src="https://img.chaolu.com.cn/ACT/mid-autumn-2023/icon.png" alt="" /></div>
            </div>
          </div>

          <div v-show="activeTab === 2" class="block-p">
            <template v-if="prizeRecord.length">
              <div v-for="item in prizeRecord" :key="item.gainTime" class="prize-item row-start-center">
                <img :src="item.prizeImgUrl" alt="" />
                <div>
                  <p class="f32 fw6 row-start-center">{{ item.prizeName }}</p>
                  <p class="p2 f22">抽奖时间：{{ item.rewardTime }}</p>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="empty">暂无奖品</div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <van-popup v-model="recordShow" safe-area-inset-bottom position="bottom" closeable class="pop-radius" close-icon="https://img.chaolu.com.cn/ACT/teamWarband-202308/close2.png">
      <p class="record-title f32 fw6">{{ curRecords.name }}打卡记录</p>
      <div class="record-box" v-if="curRecords.list.length">
        <div class="record-item row-between-center f22" v-for="(item, index) in curRecords.list" :key="index">
          <div>
            <p class="f26 fw6">{{ item.projectName }}</p>
            <p class="p2">{{ item.name }} | {{ item.venueName }}</p>
          </div>
          <div>
            <p>{{ item.dayTime }}</p>
            <p class="p2">{{ item.startTime }}</p>
          </div>
        </div>
      </div>
      <div v-else class="empty">暂无打卡记录</div>
    </van-popup>

    <van-popup v-model="show" :overlay="true" style="background-color: transparent" :close-on-click-overlay="true">
      <div class="result">
        <div class="text">
          <img v-if="result === 2" :src="prizeLevelPic" alt="" />
          <img v-else-if="result === 1" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/mid-autumn2021/reward-level0.png" alt="" />
        </div>
        <div class="dice-r">
          <img v-for="(item, index) in diceList" :key="index" :src="'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/mid-autumn2021/dice' + item + '-b.png'" alt="" />
        </div>
        <div class="pic">
          <img v-if="result === 2" :src="resultPicUrl" alt="" />
          <img v-else-if="result === 1" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/mid-autumn2021/reward0.png" alt="" />
        </div>
        <div v-if="result === 2" class="name">
          恭喜您获得：<span style="font-weight: bold">{{ prizeName }}</span>
        </div>
        <div v-else-if="result === 1" class="name">差一点点就中奖咯，继续加油！</div>
        <div class="btn" v-if="result === 2">
          <img @click="againGame(1)" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/mid-autumn2021/result-btn2.png" alt="" />
          <img @click="shareBtn" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/mid-autumn2021/result-btn1.png" alt="" />
        </div>
        <div class="btn" v-else>
          <img style="width: 100%" @click="againGame(1)" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/mid-autumn2021/result-btn3.png" alt="" />
        </div>
      </div>
      <img @click="show = false" class="result-close" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/mid-autumn2021/result-close.png" alt="" />
    </van-popup>
    <van-popup v-model="miniShareTipShow" :overlay="true" style="background-color: transparent" :close-on-click-overlay="false">
      <div style="width: 100vw; height: 100vh" @click="closeShareBox()">
        <img class="share-mini" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/anniversary2021/share-mini.png" alt="" />
      </div>
    </van-popup>

    <common-share :value="shareShow" :shareParams="shareParams" @close="shareShow = false" @share-success="shareShow = false" :shareItem="shareItem" :miniSharePoster="appTypeStr === 'mini'" :reload="true">
      <template slot="createDom">
        <div class="canvascss">
          <img src="https://img.chaolu.com.cn/ACT/mid-autumn-2023/share_poster2.png" class="canvascss_bg" />
          <div class="canvascss_left">
            <img :src="userDetail.headImg" />
          </div>
          <div class="canvascss_name ellipsis">
            {{ userDetail.nickName }}
          </div>
          <div class="canvascss_right">
            <img v-if="userDetail.qrCode" :src="'data:image/png;base64,' + userDetail.qrCode" />
          </div>
        </div>
      </template>
    </common-share>
  </div>
</template>

<script>
const webUrl = encodeURIComponent(`${window.location.origin}/#/superdeer-activity/mid-autumn-2024/index`)

const shareParams = {
  type: 'share',
  shareImage: 'https://img.chaolu.com.cn/ACT/mid-autumn-2023/index/mini.png',
  shareTitle: '中奖了！来超鹿运动和我一起好运博饼拿大奖',
  shareUrl: '/pages/webView/index?webUrl=' + window.location.origin + '/#/superdeer-activity/mid-autumn-2024/index',
}
const defaultShareParams = {
  type: 'share',
  shareImage: 'https://img.chaolu.com.cn/ACT/mid-autumn-2023/index/mini.png',
  shareTitle: '来超鹿运动和我一起好运博饼拿大奖',
  shareUrl: '/pages/webView/index?webUrl=' + window.location.origin + '/#/superdeer-activity/mid-autumn-2024/index',
}

import appMixin from '@/mixin/appMixin'
import userMixin from '@/mixin/userMixin'
import navBar from '@/components/nav-bar/nav-bar'
import commonShare from '@/components/commonShare'
import { newAppBack, initBack, gotoAppPage } from '@/lib/appMethod'
import Game from './js/index'
import { initPage } from '@/lib/utils'
import wx from 'weixin-js-sdk'

const activityNo = '2024bobing'

export default {
  data() {
    return {
      playing: false,
      isReady: true,
      isEnter: true,
      // 展示抽奖弹窗
      show: false,
      // 活动默认未结束
      isEnd: false,
      // 活动默认开始
      isStart: true,
      // 展示小程序分享弹窗
      miniShareTipShow: false,
      // 用户是否完成进馆任务
      hasEnterTheHall: false,
      // 用户博饼剩余次数
      numberOfDraws: 0,
      result: 0,
      //活动提示
      message: '',
      prizeName: '',
      // 奖励名称
      prizeLevelPic: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/mid-autumn2021/reward-level0.png',
      // 奖励图片
      resultPicUrl: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/mid-autumn2021/reward0.png',
      diceList: [],
      prizeRecord: [],
      btnImage: 'https://img.chaolu.com.cn/ACT/mid-autumn-2023/index/button.png',

      shareParams: {
        title: '',
        miniImage: 'https://img.chaolu.com.cn/ACT/mid-autumn-2023/index/mini.png',
        path: `/pages/webView/index?webUrl=${webUrl}`,
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },
      shareShow: false,
      shareItem: ['posterfriend', 'postermessage', 'save'],

      params: {},
      activeTab: 1,
      recordShow: false,
      curRecords: { list: [] },
      activityInfo: {},
      userDetail: {},
    }
  },
  mixins: [appMixin, userMixin],
  components: {
    navBar,
    commonShare,
  },
  async created() {
    await this.$getAllInfo()
    initBack()

    this.params = {
      activityNo,
      userId: this.userId,
      token: this.token,
    }
    this.isReady = false

    setTimeout(() => {
      this.isEnter = false
      this.isReady = true
    }, 2500)
    this.musicReady()

    let img = new Image()
    img.src = 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/mid-autumn2021/game-btn1.png'
    img.onload = function () {
      console.log('图片缓存完成')
    }

    this.shareParams.title = defaultShareParams.shareTitle
    wx.miniProgram.postMessage({
      data: defaultShareParams,
    })

    this.initQr()
    this.getActivity()
    this.getDetails()
    this.getPrizeRecord()
  },
  activated() {
    this.load()
    this.playing = false
    initPage('4')
  },

  methods: {
    newAppBack,

    gameStart(type) {
      if (this.numberOfDraws <= 0) {
        this.$toast({
          message: '没有抽奖次数了~',
          duration: 2000,
        })
        return
      }

      let that = this
      if ((type !== 1 && this.isReady) || (type !== 1 && this.message)) {
        this.btnImage = 'https://img.chaolu.com.cn/ACT/mid-autumn-2023/index/button_b.png'
        setTimeout(() => {
          this.btnImage = 'https://img.chaolu.com.cn/ACT/mid-autumn-2023/index/button.png'
        }, 100)
      }
      if (this.isReady) {
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          loadingType: 'spinner',
        })
        this.isReady = false
        this.$axios
          .post(this.baseURLApp + '/prizeDrawActivity/user/performPrizeDraw', this.params)
          .then((res) => {
            this.$toast.clear()
            if (res.code === '1') {
              if (res.data.result === 0) {
                that.$toast({
                  message: res.data.failMsg,
                  duration: 1000,
                })
                setTimeout(() => {
                  that.isReady = true
                }, 1500)
              } else {
                try {
                  this.$refs.soundEffect.play() // ios会报错
                  window.navigator.vibrate(200) //ios有兼容性
                } catch (e) {
                  console.log('兼容性问题', e)
                }
                Game.start(res.data.diceList)
                setTimeout(() => {
                  $('#bowl').removeClass('active')
                  that.isReady = true
                  that.show = true
                }, 2500)
                this.result = res.data.result
                this.prizeName = res.data.prizeName
                this.prizeLevelPic = res.data.prizeLevelPic
                this.resultPicUrl = res.data.resultPicUrl
                this.diceList = res.data.diceList
                this.getDetails()
              }
              this.getPrizeRecord()
            } else {
              setTimeout(() => {
                that.isReady = true
              }, 1500)
            }
          })
          .catch((res) => {
            setTimeout(() => {
              that.isReady = true
            }, 1500)
          })
      } else {
        if (this.message) {
          that.$toast({
            message: this.message,
            duration: 1000,
          })
        }
      }
    },
    getDetails() {
      this.$axios.post(this.baseURLApp + '/prizeDrawActivity/user/details', this.params).then((res) => {
        if (res.code === '1') {
          let endTime = new Date(res.data.endTime ? res.data.endTime.replace(/-/g, '/') : ''),
            startTime = new Date(res.data.startTime ? res.data.startTime.replace(/-/g, '/') : ''),
            nowTime = new Date()
          if (endTime < nowTime) {
            this.$toast({
              message: '活动已经结束',
              duration: 1000,
            })
            this.isReady = false
            this.isEnd = true
            this.message = '活动已经结束'
          }
          if (startTime > nowTime) {
            this.$toast({
              message: '活动暂未开启',
              duration: 1000,
            })
            this.isReady = false
            this.message = '活动暂未开启'
            this.isStart = false
          }
          this.numberOfDraws = res.data.numberOfDraws ? res.data.numberOfDraws : 0
          this.hasEnterTheHall = res.data.hasEnterTheHall ? res.data.hasEnterTheHall : false
        }
      })
    },
    getPrizeRecord() {
      this.$axios.post(this.baseURLApp + '/prizeDrawActivity/user/prizeRecord', { ...this.params, current: 1, size: 200 }).then((res) => {
        if (res.code === '1') {
          this.prizeRecord = res.data
        }
      })
    },

    musicReady() {
      const that = this
      $(function () {
        const music = $('#music')[0]
        music.volume = 0.2
        document.addEventListener('visibilitychange', function () {
          if (document.visibilityState === 'visible') {
            // music.play()
            // if (!music.paused) {
            //     that.playing = true
            // }
            if (that.appTypeStr === 'mini') {
              that.miniShareTipShow = false
              this.shareParams.title = defaultShareParams.shareTitle
              wx.miniProgram.postMessage({
                data: defaultShareParams,
              })
            }
          }
          if (document.visibilityState === 'hidden') {
            music.pause()
            that.playing = false
          }
        })
      })
    },
    appBack() {
      appBack()
    },
    playMusic() {
      if (this.playing) {
        this.playing = false
        this.$refs.music.pause()
      } else {
        this.playing = true
        this.$refs.music.play()
      }
    },
    autoPlay() {
      if (this.isEnd) {
        return false
      }
      if (this.$refs.music.paused) {
        this.$refs.music.play()
        setTimeout(() => {
          if (!this.$refs.music.paused) {
            this.playing = true
          }
        }, 200)
      } else {
        this.playing = true
      }
    },
    againGame(type) {
      this.show = false
      setTimeout(() => {
        this.gameStart(type)
      }, 600)
    },
    getActivity() {
      this.$axios.post(this.baseURLApp + '/punch/activity/midAutumnFestival', this.params).then((res) => {
        this.activityInfo = res.data
      })
    },
    /**
     *
     * @param type  1页面分享  2邀请分享
     */
    shareBtn() {
      if (this.message && !this.isReady) {
        this.$toast({
          message: this.message,
          duration: 1000,
        })
        return false
      }

      this.shareParams.title = shareParams.shareTitle
      let shareObj = shareParams

      if (this.appTypeStr === 'mini') {
        this.show = false
        this.miniShareTipShow = true
        wx.miniProgram.postMessage({
          data: shareObj,
        })
      } else {
        this.shareItem = ['minifriend']
        this.shareShow = true
      }
    },
    initQr() {
      this.$axios
        .post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
          token: this.token,
          userId: this.userId,
          width: 240,
          height: 240,
          webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
          scene: JSON.stringify({
            source: 'mid-autumn-2024',
            webUrl: webUrl,
          }),
        })
        .then((res) => {
          this.userDetail = res.data
        })
    },
    posterShare() {
      this.shareItem = ['posterfriend', 'postermessage', 'save']
      this.shareShow = true
    },
    closeShareBox() {
      this.miniShareTipShow = false
      this.shareParams.title = defaultShareParams.shareTitle
      wx.miniProgram.postMessage({
        data: defaultShareParams,
      })
    },
    goAppoint(v) {
      gotoAppPage(v)
    },
    showRecord(key) {
      this.curRecords.name = key === 'perRecords' ? '私教' : '团课'
      this.curRecords.list = this.activityInfo[key]
      this.recordShow = true
    },
    goRule() {
      window.scrollTo({ top: 1100, left: 0, behavior: 'smooth' })
    },
  },
}
</script>

<style lang="less" scoped>
@import './style/animation';

* {
  box-sizing: border-box;
}

.share-mini {
  position: fixed;
  top: 7px;
  right: 130px;
  width: 300px;
  z-index: 99999;
  display: block;
}

.result-close {
  width: 90px;
  height: 90px;
  margin: 48px auto 0;
  display: block;
}

.result {
  width: 626px;
  height: 964px;
  background: url('https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/mid-autumn2021/result-bg.png') no-repeat center center;
  background-size: 100%;
  position: relative;

  .text {
    padding-top: 130px;

    img {
      height: 78px;
      display: block;
      margin: 0 auto;
    }
  }

  .pic {
    img {
      width: 268px;
      height: 268px;
      display: block;
      margin: 0 auto;
    }
  }

  .dice-r {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 486px;
    margin: 0 auto;
    padding: 48px 0 56px;

    img {
      width: 80px;
      height: 80px;
      //box-shadow: 0 2px 8px 0 rgba(226, 190, 168, 0.6);
    }
  }

  .name {
    font-size: 28px;
    font-weight: bold;
    color: #e22134;
    line-height: 40px;
    text-align: center;
    margin-top: 46px;
  }

  .btn {
    display: flex;
    justify-content: space-between;
    padding: 0 34px;
    position: absolute;
    bottom: 40px;
    width: 100%;

    img {
      width: 268px;
      height: 104px;
    }
  }
}

.page-rooter-fff {
  background: #fff4ed;
  min-height: 100vh;
  //padding-bottom: 50px;
  position: relative;
  box-sizing: border-box;

  .page-img {
    width: 100%;
    overflow-x: hidden;
    .box-page {
      position: relative;
      padding: 340px 0 0;
      background: url(https://img.chaolu.com.cn/ACT/mid-autumn-2023/index/poster.png) top center no-repeat;
      background-size: 750px auto;

      .top-button {
        position: absolute;
        right: 0;
        top: 238px;
        width: 88px;
        z-index: 99;

        .button {
          line-height: 56px;
          background: rgba(36, 40, 49, 0.3);
          border-radius: 40px 0 0 40px;
          color: #fff;
          width: 100%;
          text-align: center;
          margin-bottom: 32px;
          padding-left: 6px;
        }
      }
    }

    .top-banner-box {
      width: 508px;
      height: 64px;
      margin: 0 auto;
      border-radius: 32px;
      overflow: hidden;
      box-sizing: border-box;

      .top-banner {
        color: white;
        width: 100%;
        height: 64px;
        background: rgba(0, 0, 0, 0.5);
        font-weight: 600;
        line-height: 64px;
        font-size: 24px;
        text-align: center;
        box-sizing: border-box;

        .notice-swipe {
          height: 64px;
          line-height: 64px;
        }

        .notice-swipe-item {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 0 15px;
          box-sizing: border-box;
        }
      }
    }

    .startBtn {
      margin: 0 auto 0;
      width: 458px;
      height: 174px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .number {
      color: #fff;
      text-align: center;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 32px;
    }

    .music {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      background: url('https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/mid-autumn2021/note.png') no-repeat center center;
      background-size: 100%;
      z-index: 99;
      animation: reverseRotateZ 1.2s linear infinite;

      &.on {
        animation-play-state: running;
        -webkit-animation-play-state: running;
      }

      &.stop {
        animation-play-state: paused;
        -webkit-animation-play-state: paused;
        background-image: url('https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/mid-autumn2021/note-stop.png');
      }
    }

    .rule {
      width: 64px;
      height: 64px;
      background: rgba(0, 0, 0, 0.5);
      border: 2px solid #ffffff;
      border-radius: 50%;
      box-sizing: border-box;
      position: absolute;
      right: 26px;
      top: 284px;
      font-size: 26px;
      line-height: 64px;
      color: #ffffff;
      text-align: center;
      z-index: 99;
    }

    .my-prize {
      z-index: 99;
      width: 112px;
      height: 112px;
      position: absolute;
      right: 16px;
      top: 1020px;
    }

    .task-list {
      width: 100%;
      padding: 48px 42px;

      .ii {
        width: 100%;
        margin: 0 0 69px;
      }
    }

    .tab-box {
      width: 712px;
      height: 100px;
      background-image: url(https://img.chaolu.com.cn/ACT/mid-autumn-2023/index/title_a.png);
      background-size: 100% 100%;
      position: relative;
      margin: 0 0 0 -22px;

      &.t2 {
        background-image: url(https://img.chaolu.com.cn/ACT/mid-autumn-2023/index/title_b.png);
      }

      .tab-item {
        width: 240px;
        height: 100%;
      }
    }

    .block {
      width: 100%;
      height: 434px;
      background-size: 100% 100%;
      background-image: url(https://img.chaolu.com.cn/ACT/mid-autumn-2023/bg.png);
      margin: 46px 0 0;
      padding: 36px 0 30px;
      position: relative;
      text-align: center;
      &:last-child {
        margin: 46px 0 50px;
      }
      &.a,
      &.b {
        height: 575px;
        padding: 128px 0 30px;
      }
      &.a {
        background-image: url(https://img.chaolu.com.cn/ACT/mid-autumn-2024/bg_a.png);
      }
      &.b {
        background-image: url(https://img.chaolu.com.cn/ACT/mid-autumn-2024/bg_b.png);
      }

      .p2 {
        color: #492525;
        margin: 0 0 12px;
        span {
          color: #e22134;
        }
      }

      .p3 {
        width: 558px;
        height: 100px;
        color: #fff;
        background-image: url(https://img.chaolu.com.cn/ACT/mid-autumn-2023/button_red.png);
        background-size: 100% 100%;
        margin: 36px 0 0;

        &.orange {
          background-image: url(https://img.chaolu.com.cn/ACT/mid-autumn-2023/button_orange.png);
        }
      }

      .p4 {
        color: #e22134;
        margin: 20px 0 0;
      }
      .p5 {
        color: #333333;
      }
      .p6 {
        color: #e22134;
        margin: 48px 0 0;
        span {
          font-size: 70px;
        }
      }
      .r-button {
        width: 130px;
        height: 46px;
        background: linear-gradient(180deg, #fc4b47 0%, #f80957 100%);
        border-radius: 0px 14px 0px 14px;
        position: absolute;
        right: 0;
        top: 38px;
        color: #fff2e1;

        img {
          width: 10px;
          margin: 0 0 0 8px;
        }
      }
      .progress-box {
        width: 532px;
        margin: 40px 0 0;
        position: relative;
        .road {
          width: 516px;
          height: 14px;
          background: #f6d7bf;
          border-radius: 7px;
          .progress {
            max-width: 100%;
            height: 14px;
            background: #e22134;
            border-radius: 7px;
          }
        }
        .dots {
          width: 100%;
          position: absolute;
          left: 0;
          top: -9px;
          .dot {
            width: 32px;
            height: 32px;
            background: #ffffff;
            border-radius: 50%;
            border: 6px solid #f6d7bf;
            &.active {
              border: 6px solid #e22134;
            }
            &.ok {
              background-image: url(https://img.chaolu.com.cn/ACT/mid-autumn-2024/check.png);
              background-size: 16px 14px;
              background-position: center;
              background-repeat: no-repeat;
            }
          }
        }
        .tips {
          width: 540px;
          margin: 20px 0 0;
          color: #492525;
        }
      }
    }

    .block-p {
      width: 100%;
      height: 620px;
      border: 1px solid #fb3847;
      background: linear-gradient(0deg, #fff5eb 0%, #ffeae2 100%);
      border-radius: 14px;
      padding: 4px 32px;
      margin: 33px 0 48px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;

      .prize-item {
        padding: 26px 0 32px;
        color: #e22134;

        &:not(:last-child) {
          border-bottom: 1px solid #fb3847;
        }

        img {
          margin: 0 24px 0 0;
          width: 90px;
          height: 90px;
          border-radius: 10px;
        }

        .p2 {
          margin: 10px 0 0;
        }

        .status {
          padding: 0 12px;
          height: 36px;
          color: #fff;
          background: #f74e5f;
          border-radius: 6px;
          margin: 0 0 0 8px;

          &.disabled {
            background: #d4b2b2;
          }
        }
      }

      .empty {
        color: #e22134;
        text-align: center;
        margin: 270px 0 0;
      }
    }
  }

  .pop-radius {
    padding: 44px 32px;
    border-radius: 24px 24px 0px 0px;
    background: linear-gradient(180deg, #fff 40px, #fff4ed 180px, #fff4ed 100%);

    .record-title {
      text-align: center;
      width: fit-content;
      border-radius: 9px;
      margin: 0 auto;
      padding: 0 0 4px;
      position: relative;

      &::after {
        content: '';
        width: 100%;
        height: 18px;
        border-radius: 9px;
        background: linear-gradient(270deg, rgba(255, 232, 206, 0) 0%, #fa6b76 100%);
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
      }
    }

    .record-box {
      margin: 58px 0 0;
      min-height: 40vh;
      max-height: 60vh;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }

    .record-item {
      width: 100%;
      height: 147px;
      padding: 0 33px;
      border: 1px solid #242831;
      border-radius: 14px;
      margin: 0 0 23px;

      .p2 {
        margin: 16px 0 0;
      }
    }

    /deep/.van-popup__close-icon--top-right {
      top: 44px;
    }

    .empty {
      color: #e22134;
      margin: 12vh 0;
      text-align: center;
    }
  }

  @bei: 1px;

  .canvascss {
    position: fixed;
    bottom: -200%;
    // top: 0;
    width: @bei*600;
    height: @bei*1066;
    margin: 0 auto;
    box-sizing: border-box;

    .canvascss_bg {
      width: 100%;
      height: 100%;
    }

    .canvascss_left {
      position: absolute;
      left: @bei*26;
      bottom: @bei*30;
      z-index: 1;
      width: @bei*100;
      height: @bei*100;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .canvascss_name {
      position: absolute;
      left: @bei*150;
      bottom: @bei*114;
      z-index: 1;
      width: @bei*252;
    }

    .canvascss_right {
      position: absolute;
      right: @bei*34;
      bottom: @bei*28;
      width: @bei*124;
      height: @bei*124;
      overflow: hidden;
      //background: url(https://img.chaolu.com.cn/ACT/anniversary-2023/border.png);
      background-size: 100% 100%;
      padding: @bei*12;

      img {
        border-radius: @bei*8;
        width: 100%;
        height: 100%;
      }
    }
  }

  /deep/.canvas-box {
    padding: 20px 40px !important;
    box-sizing: border-box;

    .create-img {
      width: 100% !important;
    }
  }
}
</style>
